import { BasicAmountInput } from 'app/pages/common/marketplaceAndSearch/components/AmountInput/BasicAmountInput'

import type { PersistentAmountInputProps } from './types'
import { useAmountHandlers } from './useAmountHandlers/useAmountHandlers'

export const PersistentAmountInput = (props: PersistentAmountInputProps) => {
  const { quantityInterval, minimumOrder, quantity, isDisabled, isImmutable } =
    props

  const {
    shownValue,
    isPending,
    minusHandlers,
    plusHandlers,
    changeHandler,
    blurHandler,
    keyUpHandler,
    isDeleting,
    isDeleted,
  } = useAmountHandlers({ quantityInterval, minimumOrder, quantity })

  const plusHandler = () => {
    plusHandlers.internalState()
    plusHandlers.network()
  }
  const minusHandler = () => {
    minusHandlers.internalState()
    minusHandlers.network()
  }

  const isDisabledCombined = isDisabled || isDeleting || isDeleted

  return (
    <BasicAmountInput
      {...props}
      isDisabled={isPending || isImmutable || isDisabledCombined}
      shownValue={shownValue}
      changeHandler={changeHandler}
      plusHandler={plusHandler}
      minusHandler={minusHandler}
      blurHandler={blurHandler}
      keyUpHandler={keyUpHandler}
    />
  )
}
