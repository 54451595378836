import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { SupportButton } from 'app/components/layout/SupportButton'

import { CustomerNavWrapper, HeaderActionsWrapper } from './styles'
import {
  CartButton,
  HeaderCustomerTabs,
  SearchBox,
  CustomerAccountButton,
  Logo,
} from './components'
import { CustomerNavMobile } from './CustomerNavMobile'

export const CustomerNav = () => {
  const { isTablet } = useBreakpointScreenSize()

  if (isTablet) return <CustomerNavMobile />

  return (
    <CustomerNavWrapper data-testid="mainHeader">
      <Logo />

      <SearchBox />

      <HeaderCustomerTabs />

      <HeaderActionsWrapper>
        <SupportButton />

        <CustomerAccountButton />

        <CartButton />
      </HeaderActionsWrapper>
    </CustomerNavWrapper>
  )
}
