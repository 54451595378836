import { formatDistanceToNow } from 'date-fns'
import { useCallback } from 'react'

import { DATE_LOCALES_MAP } from 'locales/consts'

import { useLocale } from 'app/hooks/useLocale'

export const useGetTimeSince = () => {
  const { currentLocale } = useLocale()

  const getTimeSince = useCallback(
    (dateString: string) =>
      formatDistanceToNow(new Date(dateString), {
        addSuffix: true,
        locale: DATE_LOCALES_MAP[currentLocale],
      }),
    [currentLocale],
  )

  return getTimeSince
}
