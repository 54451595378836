import {
  Box,
  Popover,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { camelCase } from 'lodash'
import { useRef } from 'react'

import {
  StyledPopoverBody,
  StyledPopoverContent,
  PopoverOption,
} from './styles'
import { PopoverButton } from './components'
import type { ButtonWithPopoverProps } from './types'
import { modifiers } from './modifiers'

const ButtonWithPopover = ({
  buttonText,
  triggerButtonProps,
  popoverStyleProps: {
    width,
    maxWidth,
    maxHeight,
    padding,
    placement = 'bottom-start',
    matchWidth,
  } = {},
  dataTestId,
  onCloseComplete,
  onOpenComplete,
  initialFocusRef,
  children,
}: ButtonWithPopoverProps) => {
  const popoverRef = useRef(null)
  const { isOpen, onClose, onToggle: togglePopover } = useDisclosure()

  const onOutsideClickOrEsc = () => {
    onClose()

    if (onCloseComplete) {
      onCloseComplete('clickOutsideOrEsc')
    }
  }

  const closePopover = () => {
    onClose()

    if (onCloseComplete) {
      onCloseComplete('internal')
    }
  }

  useOutsideClick({
    ref: popoverRef,
    handler: () => {
      if (isOpen) {
        onOutsideClickOrEsc()
      }
    },
  })

  return (
    <Box flex={1} ref={popoverRef}>
      <Popover
        isLazy
        placement={placement}
        isOpen={isOpen}
        onOpen={onOpenComplete}
        strategy="fixed"
        modifiers={modifiers}
        matchWidth={matchWidth}
        data-testid={`${camelCase(dataTestId)}_wrapper`}
        initialFocusRef={initialFocusRef}
      >
        <PopoverTrigger>
          <PopoverButton
            data-testid={dataTestId}
            onClick={togglePopover}
            isOpen={isOpen}
            {...triggerButtonProps}
          >
            {buttonText}
          </PopoverButton>
        </PopoverTrigger>

        <StyledPopoverContent
          width={width ?? 'auto'}
          maxWidth={maxWidth}
          maxHeight={maxHeight ?? '400px'}
        >
          <StyledPopoverBody
            padding={padding ?? '16px'}
            data-testid={`${camelCase(dataTestId)}_popoverBody`}
          >
            {children({ isOpen, closePopover })}
          </StyledPopoverBody>
        </StyledPopoverContent>
      </Popover>
    </Box>
  )
}

export { ButtonWithPopover, PopoverOption }
