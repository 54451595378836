import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { SearchInput } from 'app/components/inputs/SearchInput'
import { Divider } from 'app/components/Divider'
import { SearchIcon } from 'app/components/icons'
import { SearchPresetSelect } from 'app/pages/common/marketplaceAndSearch/components/SearchPresetSelect'
import { useMarketplaceSearchAndPagination } from 'app/pages/common/marketplaceAndSearch/useMarketplaceSearchAndPagination'

import { useCustom1310Breakpoint } from '../../useCustom1310Breakpoint'

import { useRedirectToSearchResults } from './useRedirectToSearchResults'
import { ScannerButton } from './components'

const textInputMessages = translations.components.searchBox.textInput

const SearchBoxWrapper = styled(Flex)(({ theme: { mediaQuery } }) => ({
  flex: 1,
  width: '100%',
  margin: 'auto 8px',

  [mediaQuery.small]: {
    margin: 0,
  },
}))

const DividerStyled = () => (
  <Divider isFlexChild orientation="vertical" m="6px" />
)

export const SearchBox = () => {
  const { t } = useTranslation()
  const { searchQueryParamName } = useMarketplaceSearchAndPagination()
  const [isBelow1310px] = useCustom1310Breakpoint()

  const redirectToSearchResults = useRedirectToSearchResults()

  return (
    <SearchBoxWrapper>
      <SearchInput
        disableReplaceIn
        name="mainSearchInput"
        placeholder={t(textInputMessages.placeholder)}
        searchQueryParamName={searchQueryParamName}
        onChange={(e) => redirectToSearchResults(e.target.value)}
        paddingLeft={isBelow1310px ? '120px' : '204px'}
        leftElementCustomStyles={{ margin: 'auto 8px auto 6px' }}
        leftElement={
          <Flex h="100%" align="center">
            <SearchPresetSelect />
            <DividerStyled />
            <SearchIcon style={{ flexShrink: 0, marginLeft: '12px' }} />
          </Flex>
        }
        rightElementCustomStyles={{ marginRight: '6px' }}
        rightElement={
          <Flex h="100%" align="center">
            <DividerStyled />
            <ScannerButton />
          </Flex>
        }
      />
    </SearchBoxWrapper>
  )
}
