import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import type { ModelVendorPreviewArticlesSearchSearchArticlesByEnum } from 'libs/openapiGeneratedApi/Api'

import { useMarketplaceSearchAndPagination } from '../../useMarketplaceSearchAndPagination'

const presetSelectOptionsMessages =
  translations.components.searchBox.presetSelect.options
const presetSelectShortOptionsMessages =
  translations.components.searchBox.presetSelect.shortOptions

export const ARTICLE_SEARCH_PRESETS: ModelVendorPreviewArticlesSearchSearchArticlesByEnum[] =
  [
    'ALL_ATTRIBUTES',
    'ARTICLE_NUMBER',
    'CUSTOMER_ARTICLE_NUMBER',
    'VENDOR_ARTICLE_NUMBER',
    'EAN',
  ]

export const useSearchPresetSelect = () => {
  const { t } = useTranslation()

  const { searchPreset } = useMarketplaceSearchAndPagination()

  const options = ARTICLE_SEARCH_PRESETS.map((preset) => ({
    id: preset,
    label: t(presetSelectOptionsMessages[preset]),
    shortLabel: t(presetSelectShortOptionsMessages[preset]),
  }))

  const activeSearchPreset =
    options.find((option) => option.id === searchPreset) ?? options[0]

  return {
    options,
    activeSearchPreset,
  }
}
