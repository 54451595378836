import { useTranslation } from 'react-i18next'
import { forwardRef, InputGroup } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import {
  InputLeftElementStyled,
  BareInputStyled,
  InputRightElementStyled,
} from './styles'
import type { BareInputProps } from './types'

const messages = translations.formFields

export const BareInput = forwardRef<BareInputProps, 'input'>(
  (
    {
      name,
      value,
      isError,
      isDisabled,
      dataTestId,
      leftElement,
      leftElementCustomStyles,
      rightElement,
      rightElementCustomStyles,
      size = 'md',
      placeholder,
      type,
      maxWidth,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const defaultTextMaxLength = 10_000

    const isTextType = !type || type === 'text'

    const commonProps = {
      size,
      isError,
      isDisabled,
    }

    return (
      <InputGroup variant="filled" maxWidth={maxWidth}>
        {leftElement && (
          <InputLeftElementStyled
            {...commonProps}
            leftElementCustomStyles={leftElementCustomStyles}
          >
            {leftElement}
          </InputLeftElementStyled>
        )}

        <BareInputStyled
          ref={ref}
          id={name}
          value={value ?? '' /* prevent uncontrolled inputs */}
          aria-label={name}
          name={name}
          hasLeftElement={!!leftElement}
          hasRightElement={!!rightElement}
          placeholder={placeholder || t(messages.genericInputPlaceholder)}
          type={type}
          isInvalid={isError}
          {...(isTextType && { maxLength: defaultTextMaxLength })}
          {...commonProps}
          {...rest}
        />

        {rightElement && (
          <InputRightElementStyled
            {...commonProps}
            rightElementCustomStyles={rightElementCustomStyles}
          >
            {rightElement}
          </InputRightElementStyled>
        )}
      </InputGroup>
    )
  },
)
