import { MenuButton } from '@chakra-ui/react'
import type { MouseEvent } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'

import { Button } from 'app/components/Buttons/Button'

export const ActionsMenuButton = () => (
  <Button
    size="squareMd"
    aria-label="more"
    as="div"
    variant="secondary"
    onClick={(event: MouseEvent<HTMLButtonElement>) => event.stopPropagation()}
    style={{ padding: 0 }}
  >
    <MenuButton padding="10px">
      <BsThreeDotsVertical size={16} />
    </MenuButton>
  </Button>
)
