import { useFormik } from 'formik'

import { useArticleListAssignmentsQuery } from 'app/containers/customers/ArticleLists/Assignments/List'
import { useUpdateArticleListAssignmentsMutation } from 'app/containers/customers/ArticleLists/Assignments/Update'
import type {
  ArticleListId,
  MODEL__ArticleListAssignments,
} from 'app/containers/customers/ArticleLists/types'
import { useCartArticleDataLight } from 'app/containers/customers/CurrentCart/useCartArticleDataLight'

export type FormValues = { articleListsAssigned: ArticleListId[] }

const useArticleListAssignmentsFormInitialValues = (articleId: string) => {
  const { data } = useArticleListAssignmentsQuery(articleId)

  const initialArticleListsAssigned = {
    articleListsAssigned:
      data
        ?.filter(({ articleAssigned }) => articleAssigned)
        .map(({ articleListId }) => articleListId) ?? [],
  }

  return { initialArticleListsAssigned }
}

const useArticleListAssignmentsFormOnSubmit = ({
  articleId,
  articleListAssignments,
  initialQuantity,
}: {
  articleId: string
  articleListAssignments: MODEL__ArticleListAssignments
  initialQuantity: number
}) => {
  const { mutate: updateArticleListAssignments } =
    useUpdateArticleListAssignmentsMutation()

  const onSubmit = (values: FormValues, onSuccess: () => void) => {
    const assignments =
      articleListAssignments?.map(({ articleListId }) => ({
        articleListId,
        articleAssigned: values.articleListsAssigned.includes(articleListId),
      })) ?? []

    return updateArticleListAssignments(
      {
        articleId,
        assignments,
        ...(initialQuantity > 0 ? { initialQuantity } : {}),
      },
      { onSuccess },
    )
  }

  return onSubmit
}

export const useArticleListAssignmentsForm = ({
  articleId,
  hideArticleListAssignmentsModalFlow,
}: {
  articleId: string
  hideArticleListAssignmentsModalFlow: () => void
}) => {
  const { data: articleListAssignments = [] } =
    useArticleListAssignmentsQuery(articleId)

  const { inCartQuantity: initialQuantity } = useCartArticleDataLight({
    articleId,
  })

  const { initialArticleListsAssigned } =
    useArticleListAssignmentsFormInitialValues(articleId)

  const onSubmit = useArticleListAssignmentsFormOnSubmit({
    articleId,
    articleListAssignments,
    initialQuantity,
  })

  const articleListAssignmentsFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialArticleListsAssigned,
    onSubmit: (values) => onSubmit(values, hideArticleListAssignmentsModalFlow),
  })

  const {
    values: { articleListsAssigned: currentArticleListsAssigned },
    setFieldValue,
  } = articleListAssignmentsFormik

  const assignArticleList = async (newArticleListId: ArticleListId) => {
    await setFieldValue('articleListsAssigned', [
      ...currentArticleListsAssigned,
      newArticleListId,
    ])
  }

  return {
    articleListAssignmentsFormik,
    assignArticleList,
  }
}
