import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Tabs, TabList } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { useCheckCustomerPermission } from 'app/containers/common/Me/CompanyUser'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { HEADER_CUSTOMER_TABS_MAP } from './consts'
import type { HeaderCustomersTabData } from './types'
import { TabStyled, TabsWrapper } from './styles'

const messages = translations.layout.customer.tabs

export const useHeaderTabsData = () => {
  const { t } = useTranslation()
  const { isAdmin } = useCheckCustomerPermission()
  const { isMobile } = useBreakpointScreenSize()

  const tabsData: HeaderCustomersTabData[] = [
    {
      title: t(messages.home),
      key: HEADER_CUSTOMER_TABS_MAP.home,
    },
    {
      title: t(messages.shopping),
      key: HEADER_CUSTOMER_TABS_MAP.shopping,
    },

    {
      title: t(messages.vendors),
      key: HEADER_CUSTOMER_TABS_MAP.vendors,
    },

    ...(isAdmin && !isMobile
      ? [
          {
            title: t(messages.companyAndSettings),
            key: HEADER_CUSTOMER_TABS_MAP.companyAndSettings,
          },
        ]
      : []),
  ]

  return tabsData
}

export const HeaderCustomerTabs = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const tabsData = useHeaderTabsData()

  const currentActiveKey = tabsData.findIndex((tab) =>
    pathname.includes(tab.key),
  )

  return (
    <TabsWrapper>
      <Tabs
        data-testid="customerHeaderTabs"
        variant="unstyled"
        index={currentActiveKey}
      >
        <TabList>
          {tabsData.map(({ title }, index) => (
            <TabStyled
              key={title}
              data-testid={title}
              onClick={() => history.push(tabsData[index].key)}
            >
              {title}
            </TabStyled>
          ))}
        </TabList>
      </Tabs>
    </TabsWrapper>
  )
}
