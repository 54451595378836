import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Box } from '@chakra-ui/react'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import { Typography } from 'app/components/Typography'
import { useOciSession } from 'app/containers/common/Auth'
import { Divider } from 'app/components/Divider'
import { LanguageAccordion } from 'app/components/layout/chooseLanguage'
import { MenuItemWrapper } from 'app/components/ActionsMenu/styles'
import { AccountMenuUserAndCompanyInfo } from 'app/components/layout/Layout/components/Header/commonCompanyNavComponents/components/AccountMenuUserAndCompanyInfo'
import { ChangeCompanyActions } from 'app/components/layout/Layout/components/Header/commonCompanyNavComponents/components'

const messages = translations.header

export const MyAccountContent = () => {
  const { t } = useTranslation()
  const { isOciSessionFlow } = useOciSession()

  return (
    <Box data-testid="userMenu">
      <AccountMenuUserAndCompanyInfo
        areCompanyDetailsHidden={isOciSessionFlow}
      />

      {!isOciSessionFlow && (
        <>
          <MenuItemWrapper>
            <Link to={PATHS.customer.companyAndSettings.deliveryAddresses}>
              {t(messages.deliveryAddresses)}
            </Link>
          </MenuItemWrapper>

          <Divider m="4px 12px" width="auto" />
        </>
      )}

      <ChangeCompanyActions
        // TODO: Refactor MenuItemWrapper from styled to regular component https://simplesystem.atlassian.net/browse/S2NG-9369
        // ButtonComponent={MenuItemWrapper}
        ButtonComponent={(props) => <MenuItemWrapper {...props} />}
      />

      <MenuItemWrapper data-testid="articlesListsActionItem">
        <Link to={PATHS.customer.companyAndSettings.articleLists}>
          {t(messages.articlesLists)}
        </Link>
      </MenuItemWrapper>

      {!isOciSessionFlow && (
        <>
          <MenuItemWrapper>
            <Link to={PATHS.customer.orders}>
              <Typography>{t(messages.orderHistory)}</Typography>
            </Link>
          </MenuItemWrapper>

          <MenuItemWrapper>
            <Link to={PATHS.customer.companyAndSettings.deliveriesAndPayments}>
              <Typography>{t(messages.deliveriesAndPayments)}</Typography>
            </Link>
          </MenuItemWrapper>

          <MenuItemWrapper>
            <Link to={PATHS.customer.budgetLimits}>
              <Typography>{t(messages.budgetLimits)}</Typography>
            </Link>
          </MenuItemWrapper>
        </>
      )}

      <Divider m="4px 12px" width="auto" />

      <LanguageAccordion />
    </Box>
  )
}
