import styled from '@emotion/styled'
import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'

import { mediaQuery } from 'theme/mediaQueries'

import { useCurrentUser } from 'app/containers/common/Auth'

import { usePageOffsetTop } from './usePageOffsetTop'

export const HeaderWrapper = styled((props: FlexProps) => {
  const pageOffsetTop = usePageOffsetTop()

  return <Flex h={pageOffsetTop} {...props} />
})(
  ({ theme: { zIndexes, colors } }) =>
    ({
      flexDirection: 'column',
      position: 'fixed',
      zIndex: zIndexes.header,
      width: '100vw',
      background: colors.white,

      [mediaQuery.print]: {
        display: 'none',
      },
    }) as const,
)
export const PageWrapper = ({ backgroundColor, ...rest }: FlexProps) => {
  const pageOffsetTop = usePageOffsetTop()
  const { isCustomer, isOciCustomer } = useCurrentUser()

  return (
    <Flex
      bg={backgroundColor}
      minH={`calc(100vh - ${pageOffsetTop})`}
      pt={pageOffsetTop}
      sx={{
        [mediaQuery.print]: { pt: 0 },
      }}
      // INFO: this prevents Page content jumping when scrollbar is dynamically added e.g. after expanding Table rows
      {...(isCustomer || isOciCustomer // INFO: it's different for non customer as there is a SideNavigation
        ? { ml: 'calc(100vw - 100%)' }
        : { mr: 'calc(-1 * (100vw - 100%))' })}
      {...rest}
    />
  )
}

type MainProps = {
  shouldApplyWiderMobileSpacing?: boolean
}

const MainComponent = ({
  shouldApplyWiderMobileSpacing,
  ...props
}: MainProps & BoxProps) => <Box {...props} />

const Main = styled(MainComponent)<MainProps>(
  ({ theme: { dimensions }, shouldApplyWiderMobileSpacing }) => ({
    position: 'relative',
    flexGrow: 1,
    width: '100%',
    margin: '0 auto',
    padding: dimensions.contentPadding,

    [mediaQuery.small]: {
      padding: shouldApplyWiderMobileSpacing
        ? dimensions.contentPaddingMobileWide
        : dimensions.contentPaddingMobile,
    },
    [mediaQuery.print]: {
      padding: 0,
    },
  }),
)

export const CustomerContentWrapper = styled(Main)(
  ({ theme: { dimensions } }) => ({
    maxWidth: dimensions.customerContentMaxWidth,
  }),
)

export const BackofficeContentWrapper = styled(Main)(
  ({ theme: { dimensions } }) => ({
    maxWidth: dimensions.backofficeContentMaxWidth,
  }),
)
