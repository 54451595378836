import { assignedGeneralLedgersMockData } from 'app/containers/customers/GeneralLedgers/AssignedToCustomer/mock/data'
import { assignedAccountTypesMockData } from 'app/containers/customers/AccountTypes/AssignedToCustomer'
import { assignedAccountTypeValuesMockData } from 'app/containers/customers/AccountTypes/Values/AssignedToCustomer'

export const cartLevelTypeAndValue = {
  typeId: assignedAccountTypesMockData.accountTypes[0].id,
  valueId: assignedAccountTypeValuesMockData.accountTypeValues[0].id,
  freeTextValue: 'Cart-level free text value',
}
export const itemLevelTypeAndValue = {
  typeId: assignedAccountTypesMockData.accountTypes[1].id,
  valueId: assignedAccountTypeValuesMockData.accountTypeValues[1].id,
  freeTextValue: 'Item-level free text value',
}

export const defaultGeneralLedger =
  assignedGeneralLedgersMockData.generalLedgers[1]
export const cartLevelGeneralLedger =
  assignedGeneralLedgersMockData.generalLedgers[0]
export const cartLevelGeneralLedgerId = cartLevelGeneralLedger.id

export const defaultCartAdditionalAccountingInfo =
  'Cart general accounting info'
