import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import { useCurrentUser, useOciSession } from 'app/containers/common/Auth'
import { ActionItem } from 'app/components/ActionsMenu/styles'

import { CompanyAccountMenu } from '../../../commonCompanyNavComponents'

import { CustomerAccountButtonTrigger } from './components'

export const CustomerAccountButton = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { isUserImpersonated } = useCurrentUser()
  const { isOciSessionFlow } = useOciSession()

  const isLogoutAvailable = !isUserImpersonated && !isOciSessionFlow

  return (
    <CompanyAccountMenu
      triggerButton={<CustomerAccountButtonTrigger />}
      additionalMenuOptions={
        <>
          {!isOciSessionFlow && (
            <ActionItem
              onClick={() => history.push(PATHS.customer.orders)}
              data-testid="orderHistory"
            >
              {t(translations.header.orderHistory)}
            </ActionItem>
          )}

          <ActionItem
            onClick={() =>
              history.push(PATHS.customer.companyAndSettings.articleLists)
            }
            data-testid="articlesListsActionItem"
          >
            {t(translations.header.articlesLists)}
          </ActionItem>

          {!isOciSessionFlow && (
            <>
              <ActionItem
                onClick={() =>
                  history.push(
                    PATHS.customer.companyAndSettings.deliveriesAndPayments,
                  )
                }
                data-testid="deliveriesAndPaymentsActionItem"
              >
                {t(translations.header.deliveriesAndPayments)}
              </ActionItem>

              <ActionItem
                onClick={() => history.push(PATHS.customer.budgetLimits)}
                data-testid="budgetLimitsActionItem"
              >
                {t(translations.header.budgetLimits)}
              </ActionItem>
            </>
          )}
        </>
      }
      isLogoutAvailable={isLogoutAvailable}
      areCompanyDetailsHidden={isOciSessionFlow}
    />
  )
}
