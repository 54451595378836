import type { SetRequired } from 'type-fest'

import type { ModelCustomerArticleSearchData } from 'libs/openapiGeneratedApi/Api'

import type { VendorId } from 'app/models/user'
import { TEST_ARTICLE_EXTERNAL_PRICE } from 'app/containers/common/consts'

export const articlesSearchMockedData: ModelCustomerArticleSearchData = {
  items: [
    {
      vendor: {
        id: '722e31c7-c5ed-43d2-8690-994726e332dc' as VendorId,
        name: 'Hoffmann Group',
      },
      article: {
        id: '4906cd7b-2810-4671-963b-81be91d2ad25',
        name: 'Office paper',
        number: 'NO.123456',
        customerArticleNumber: 'VENO.2211233',
        manufacturerArticleNumber: 'AAB73984',
        ean: 'EAN123453',
        manufacturer: 'Woodless Woods GmbH',
        deliveryTime: 1,
        thumbnailUrl:
          'https://www.promotionalpromo.com/Upfiles/Prod_r/Basketball_165288.jpg',
        quantityInterval: 2,
        minimumOrder: 4,
        coreArticle: true,
        closedMarketplace: true,
        explanation: undefined,
        tax: 0,
        orderUnit: 'PK',
        isIncludedInArticleList: false,
        orderOptions: {
          restricted: false,
          priceQuantity: '1',
          pricing: [
            {
              quantity: '1',
              price: {
                amount: TEST_ARTICLE_EXTERNAL_PRICE,
                currency: 'GBP',
              },
              source: { type: 'CATALOG' },
            },
          ],
        },
      },
    },
    {
      vendor: {
        id: '122e31c7-c5ed-43d2-8690-994726e332dc' as VendorId,
        name: 'Hoffmann Group',
      },
      article: {
        id: '2906cd7b-2810-4671-963b-81be91d2ad25',
        name: 'Office chairs',
        number: 'NO.123457',
        customerArticleNumber: 'VENO.2211234',
        manufacturerArticleNumber: 'AXB03023',
        ean: 'EAN123456',
        manufacturer: 'Ikea',
        deliveryTime: 1,
        thumbnailUrl:
          'https://www.promotionalpromo.com/Upfiles/Prod_r/Basketball_165288.jpg',
        contentAmount: '50',
        contentUnit: 'ST',
        minimumOrder: 5,
        coreArticle: false,
        closedMarketplace: true,
        explanation: undefined,
        orderUnit: 'PK',
        tax: 0,
        isIncludedInArticleList: true,
        orderOptions: {
          restricted: true,
          priceQuantity: '5',
          pricing: [
            {
              quantity: '5',
              price: {
                amount: 50000,
                currency: 'GBP',
              },
              source: {
                type: 'PRICE_LIST',
                priceListFilename: 'prices_for_customer.csv',
              },
            },
            {
              quantity: '10',
              price: {
                amount: 40000,
                currency: 'GBP',
              },
            },
            {
              quantity: '15',
              price: {
                amount: 30000,
                currency: 'GBP',
              },
            },
          ],
        },
      },
    },
    // INFO: This is the article with no prices defined - they need to be fetched separately
    {
      vendor: {
        id: '722e31c7-c5ed-43d2-8690-994726e332dc' as VendorId,
        name: 'Hoffmann Group',
      },
      article: {
        id: 'uuid14135135',
        name: 'Office desk',
        number: 'NO.uuid14135135',
        customerArticleNumber: 'VENO.2211235',
        manufacturerArticleNumber: 'H830DB',
        ean: 'EAN123457',
        manufacturer: 'Ikea',
        deliveryTime: 1,
        thumbnailUrl:
          'https://www.promotionalpromo.com/Upfiles/Prod_r/Basketball_165288.jpg',
        minimumOrder: 1,
        coreArticle: true,
        closedMarketplace: false,
        explanation: undefined,
        orderUnit: 'piece',
        tax: 0,
        isIncludedInArticleList: false,
      },
    },
    {
      vendor: {
        id: '122e31c7-c5ed-43d2-8690-994726e332dc' as VendorId,
        name: 'Hoffmann Group',
      },
      article: {
        id: '1206cd7b-2810-4671-963b-81be91d2ad25',
        name: 'Steel',
        number: 'NO.uuid635621',
        customerArticleNumber: 'VENO.2211236',
        ean: 'EAN123456',
        manufacturer: 'Krupp',
        deliveryTime: 2,
        thumbnailUrl:
          'https://www.promotionalpromo.com/Upfiles/Prod_r/Basketball_165288.jpg',
        minimumOrder: 1,
        orderUnit: 'piece',
        coreArticle: false,
        closedMarketplace: false,
        isIncludedInArticleList: true,
        explanation: undefined,
        tax: 0,
        orderOptions: {
          restricted: false,
          priceQuantity: '1',
          pricing: [
            {
              quantity: '1',
              price: {
                amount: 50000,
                currency: 'GBP',
              },
            },
          ],
        },
      },
    },
    {
      vendor: {
        id: '722e31c7-c5ed-43d2-8690-994726e332dc' as VendorId,
        name: 'Hoffmann Group',
      },
      article: {
        id: 'uuid513564',
        name: 'Coal',
        number: 'NO.uuid513564',
        customerArticleNumber: 'VENO.2211237',
        ean: 'EAN123456',
        manufacturer: 'Coal Mining AG',
        deliveryTime: 7,
        thumbnailUrl:
          'https://www.promotionalpromo.com/Upfiles/Prod_r/Basketball_165288.jpg',
        minimumOrder: 1,
        coreArticle: false,
        closedMarketplace: false,
        explanation: undefined,
        tax: 0,
        orderUnit: 'piece',
        isIncludedInArticleList: true,
        orderOptions: {
          restricted: false,
          priceQuantity: '1',
          pricing: [
            {
              quantity: '1',
              price: {
                amount: 50000,
                currency: 'GBP',
              },
            },
          ],
        },
      },
    },
  ],
  metadata: {
    page: 1,
    pageCount: 3,
    pageSize: 2,
    term: 'testterm',
    hitCount: 5,
  },
}

// TODO: Should we have a custom type for the filters?
export const articleFilterValues: SetRequired<
  ModelCustomerArticleSearchData,
  | 'manufacturers'
  | 'vendors'
  | 'prices'
  | 'features'
  | 'customCategories'
  | 'eClass5Categories'
  | 'eClass7Categories'
> = {
  items: [],
  metadata: { page: 1, pageSize: 10, pageCount: 1, term: '', hitCount: 1 },
  manufacturers: [
    {
      name: '1st manufacturer',
      count: 3,
    },
    {
      name: '3 manufacturer',
      count: 9,
    },
  ],
  vendors: [
    {
      vendorId: '3fa742bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
      count: 19,
    },
    {
      vendorId: '1fa742bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
      count: 8,
    },
  ],
  prices: [
    {
      minAmount: 1234,
      maxAmount: 5678,
      currency: 'GBP',
    },
  ],
  features: [
    {
      name: 'Inhalt',
      count: 8753,
      values: [
        {
          value: '10 Stk.',
          count: 977,
        },
      ],
    },
  ],
  customCategories: {
    selectedPath: {
      type: 'CUSTOM',
      pathGroups: [],
    },
    sameLevel: [
      {
        id: '12345678',
        name: 'Tools',
        count: 5,
      },
    ],
    children: [
      {
        id: '12345678',
        name: 'Tools',
        count: 5,
      },
    ],
  },
  eClass5Categories: {
    selectedPath: {
      type: 'ECLASS_5_1',
      pathGroups: [],
    },
    sameLevel: [
      {
        id: '12345678',
        name: 'Tools',
        count: 1,
      },
    ],
    children: [
      {
        id: '12345678',
        name: 'Tools',
        count: 1,
      },
    ],
  },
  eClass7Categories: {
    selectedPath: {
      type: 'ECLASS_7_1',
      pathGroups: [],
    },
    sameLevel: [
      {
        id: '12345678',
        name: 'Tools',
        count: 2,
      },
    ],
    children: [
      {
        id: '12345678',
        name: 'Tools',
        count: 2,
      },
    ],
  },
}
