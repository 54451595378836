import type { ReactNode } from 'react'
import { Fragment } from 'react'
import type { PopoverProps } from '@chakra-ui/react'
import { Popover, PopoverTrigger, PopoverBody, Portal } from '@chakra-ui/react'

import { PopoverContentStyled } from './styles'
import { SupportPopoverContent } from './SupportPopoverContent'

export type SupportPopoverProps = {
  children: (state: { isOpen: boolean; onClose: () => void }) => ReactNode
  shouldBeWrappedInPortal?: boolean
  placement?: PopoverProps['placement']
}

export const SupportPopover = ({
  children,
  shouldBeWrappedInPortal,
  placement = 'bottom-end',
}: SupportPopoverProps) => {
  const ContentWrapper = shouldBeWrappedInPortal ? Portal : Fragment

  return (
    <Popover placement={placement}>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>{children({ isOpen, onClose })}</PopoverTrigger>

          <ContentWrapper>
            <PopoverContentStyled>
              <PopoverBody width="320px" padding="16px">
                <SupportPopoverContent withTitle />
              </PopoverBody>
            </PopoverContentStyled>
          </ContentWrapper>
        </>
      )}
    </Popover>
  )
}
