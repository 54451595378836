import type { SetRequired } from 'type-fest'

import type { ModelCustomerArticleSearchData } from 'libs/openapiGeneratedApi/Api'

import type { VendorId } from 'app/models/user'

export const searchData: SetRequired<
  ModelCustomerArticleSearchData,
  'items' | 'metadata'
> = {
  metadata: {
    page: 1,
    pageCount: 34,
    pageSize: 2,
    term: '',
    hitCount: 5,
  },
  items: [],
}

type ModelCustomerArticleSearchDataWithAllFiltersExceptCategories = SetRequired<
  ModelCustomerArticleSearchData,
  'manufacturers' | 'vendors' | 'prices' | 'features'
>

export const searchValuesNoFilters: ModelCustomerArticleSearchDataWithAllFiltersExceptCategories =
  {
    items: [],
    metadata: { page: 1, pageSize: 10, pageCount: 1, term: '', hitCount: 1 },
    manufacturers: [],
    vendors: [],
    prices: [],
    features: [],
    // TODO: In reality it's null, should be adjusted in the Open API definition
    customCategories: undefined,
    eClass5Categories: undefined,
    eClass7Categories: undefined,
  }

export const searchValuesWithVendorsList: ModelCustomerArticleSearchDataWithAllFiltersExceptCategories =
  {
    ...searchValuesNoFilters,
    vendors: [
      {
        vendorId: '3fa742bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 19,
      },
      {
        vendorId: '1fa742bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 8,
      },
      {
        vendorId: '9+5742bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 5,
      },
      {
        vendorId: '96842bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 1,
      },
      {
        vendorId: '111142bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 6,
      },
      {
        vendorId: '235142bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 3,
      },
      {
        vendorId: '996842bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 5,
      },
      {
        vendorId: '864842bc-7867-4397-a8b5-d97fe58869dd' as VendorId,
        count: 23,
      },
    ],
  }

export const searchValuesWithPricesList: ModelCustomerArticleSearchDataWithAllFiltersExceptCategories =
  {
    ...searchValuesNoFilters,
    prices: [
      {
        minAmount: 1000,
        maxAmount: 300000,
        currency: 'GBP',
      },
    ],
  }

export const searchValuesWithManufacturersList: ModelCustomerArticleSearchDataWithAllFiltersExceptCategories =
  {
    ...searchValuesNoFilters,
    manufacturers: [
      {
        name: 'memo',
        count: 29,
      },
      {
        name: 'Magnetoplan',
        count: 15,
      },
      {
        name: 'edding',
        count: 14,
      },
      {
        name: 'Schneider Electric',
        count: 13,
      },
      {
        name: 'Exacompta',
        count: 11,
      },
      {
        name: 'Schneider',
        count: 9,
      },
      {
        name: 'GBC',
        count: 7,
      },
      {
        name: 'STAEDTLER',
        count: 7,
      },
    ],
  }
