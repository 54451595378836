import { Route } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { Search } from 'app/pages/customers/Search/Loadable'
import { ArticleDetails } from 'app/pages/common/marketplaceAndSearch/ArticleDetails/Loadable'
import { ArticleLists } from 'app/pages/customers/ArticleLists/List/Loadable'
import { ArticleListDetails } from 'app/pages/customers/ArticleLists/ArticleListDetails/Loadable'
import { ShareArticleList } from 'app/pages/customers/ArticleLists/ShareArticleList/Loadable'
import { ArticlesListCollaborate } from 'app/pages/customers/ArticleLists/Collaborate/Loadable'
import { OciCatalogs } from 'app/pages/customers/OciCatalogs/Loadable'
import { Home } from 'app/pages/customers/Home/Loadable'
import { Vendors } from 'app/pages/customers/Vendors/Loadable'
import { ArticleListGenerateBarcodes } from 'app/pages/customers/ArticleLists/GenerateBarcodes/Loadable'
import { Scanner } from 'app/pages/customers/Scanner/Scanner'
import { FastOrder } from 'app/pages/customers/FastOrder/FastOrder'

const settingsPaths = PATHS.customer.companyAndSettings

export const renderCustomerAndOciCommonRoutes = () => [
  <Route path={PATHS.customer.home} key={PATHS.customer.home} exact>
    <Home />
  </Route>,

  <Route path={PATHS.customer.search} key={PATHS.customer.search} exact>
    <Search />
  </Route>,

  <Route path={PATHS.customer.scanner} key={PATHS.customer.scanner} exact>
    <Scanner />
  </Route>,

  <Route path={PATHS.customer.fastOrder} key={PATHS.customer.fastOrder} exact>
    <FastOrder />
  </Route>,

  <Route exact path={PATHS.customer.vendors} key={PATHS.customer.vendors}>
    <Vendors />
  </Route>,

  <Route path={PATHS.customer.ociCatalogs} key={PATHS.customer.ociCatalogs}>
    <OciCatalogs />
  </Route>,

  <Route
    path={PATHS.customer.articleDetails}
    key={PATHS.customer.articleDetails}
  >
    <ArticleDetails />
  </Route>,

  <Route
    exact
    path={settingsPaths.articleLists}
    key={settingsPaths.articleLists}
  >
    <ArticleLists />
  </Route>,

  <Route
    exact
    path={settingsPaths.articleListDetails}
    key={settingsPaths.articleListDetails}
  >
    <ArticleListDetails />
  </Route>,

  <Route
    exact
    path={settingsPaths.articleListsCollaborate}
    key={settingsPaths.articleListsCollaborate}
  >
    <ArticlesListCollaborate />
  </Route>,

  <Route
    exact
    path={settingsPaths.shareArticleList}
    key={settingsPaths.shareArticleList}
  >
    <ShareArticleList />
  </Route>,

  <Route
    exact
    path={settingsPaths.articleListGenerateBarcodes}
    key={settingsPaths.articleListGenerateBarcodes}
  >
    <ArticleListGenerateBarcodes />
  </Route>,
]
