import { Flex } from '@chakra-ui/react'

import { Typography } from 'app/components/Typography'
import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'

import type { AddToCartButtonState } from './types'
import { useBareButtonStateProps } from './useBareButtonStateProps'

type BareAddToCartButtonProps = Omit<ButtonProps, 'children'> & {
  isInline?: boolean
  state: AddToCartButtonState
}

const adjustSize = (size: ButtonProps['size'], isInline: boolean) => {
  if (!isInline) return size
  switch (size) {
    case 'sm':
      return 'squareSm'
    case 'md':
      return 'squareMd'
    default:
      return size
  }
}

export const BareAddToCartButton = ({
  color,
  isInline = false,
  size,
  state,
  ...rest
}: BareAddToCartButtonProps) => {
  const adjustedSize = adjustSize(size, isInline)
  const { buttonProps, icon, label, ariaLabel } = useBareButtonStateProps({
    state,
    isInline,
  })

  return (
    <Button
      isFullWidth={!isInline}
      data-testid="addToCart"
      size={adjustedSize}
      transition="background .2s"
      aria-label={ariaLabel}
      {...rest}
      {...buttonProps}
    >
      <Flex as="span" alignItems="center">
        {icon}
        {!isInline && label && (
          <Typography
            textStyle="textNormalSb"
            color={color ?? 'white'}
            display="inline"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {label}
          </Typography>
        )}
      </Flex>
    </Button>
  )
}
