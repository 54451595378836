import { memo } from 'react'
import { useHoverDirty } from 'react-use'
import { useImage } from '@chakra-ui/react'

import { ArticlePlaceholderIcon } from './FallbackIcon'
import { ImageContainer, StyledImage, ZoomTarget } from './styles'
import { getShouldRerender } from './getShouldRerender'
import type { MemoizedImageProps } from './types'
import { useZoomOnHover } from './useZoom'

export const MemoizedImage = memo(
  ({
    PlaceholderIcon = ArticlePlaceholderIcon,
    src,
    enableZoom,
    size,
    ...rest
  }: MemoizedImageProps) => {
    const imageStatus = useImage({ src })

    const { imageContainerRef, zoomTargetRef } = useZoomOnHover({
      src,
      enableZoom,
      size,
      imageStatus,
    })
    const isImageHover = useHoverDirty(imageContainerRef)

    const imageWithZoomRendered = (
      <ImageContainer ref={imageContainerRef} size={size}>
        <StyledImage {...rest} src={src} size={size} enableZoom={enableZoom} />
        <ZoomTarget ref={zoomTargetRef} isImageHover={isImageHover} />
      </ImageContainer>
    )

    return imageStatus === 'loaded' ? (
      imageWithZoomRendered
    ) : (
      <PlaceholderIcon size={size} {...rest} />
    )
  },
  (prevProps, nextProps) => !getShouldRerender(prevProps, nextProps),
)
