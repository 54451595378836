import { Image } from '@chakra-ui/react'
import type { ImageProps } from '@chakra-ui/react'
import { useState } from 'react'

enum LoadState {
  DEFAULT = 'default', // default
  ERROR = 'error', // temp value switching to FALLBACK
  FALLBACK = 'fallback',
}

type LogoWithFallbackProps = ImageProps & {
  readonly logoUrl: string
  readonly fallbackUrl?: string
}

export const LogoWithFallback = (props: LogoWithFallbackProps) => {
  const { logoUrl, fallbackUrl = '/simplesystem-logo.svg', ...rest } = props

  const [loadStatus, setLoadStatus] = useState<LoadState>(LoadState.DEFAULT)
  const [imageUrl, setImageUrl] = useState(logoUrl)
  const isLoaded = loadStatus !== LoadState.ERROR

  const handleError = () => {
    setLoadStatus(LoadState.ERROR)
    setImageUrl(fallbackUrl)
  }
  const handleLoad = () => {
    setLoadStatus((status) =>
      status === LoadState.ERROR ? LoadState.FALLBACK : LoadState.DEFAULT,
    )
  }

  return (
    <Image
      src={imageUrl}
      onError={handleError}
      onLoad={handleLoad}
      visibility={isLoaded ? 'visible' : 'hidden'}
      objectFit="contain"
      {...rest}
    />
  )
}
