import type { CSSProperties, ReactNode } from 'react'
import type { TextProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import { CopyButton } from 'app/components/Buttons/CopyButton'

import { Typography } from '../Typography'

import { DisplayInfoRowContainer } from './styles'

export type DisplayInfoRowProps = {
  label: ReactNode
  value?: ReactNode
  showCopyButton?: boolean
  shouldNotWrapLabel?: boolean
  allowContentWrapping?: boolean
  textAlign?: TextProps['textAlign']
  alignItems?: CSSProperties['alignItems']
  icon?: ReactNode
  withoutTopBorder?: boolean
  withoutBottomBorder?: boolean
  dataTestId?: string
}

export const DisplayInfoRow = ({
  label,
  value,
  showCopyButton,
  shouldNotWrapLabel,
  allowContentWrapping,
  textAlign,
  alignItems,
  icon,
  withoutTopBorder,
  withoutBottomBorder = true,
  dataTestId: passedDataTestId,
}: DisplayInfoRowProps) => {
  const isButtonDisplayed = showCopyButton && value

  const testId =
    passedDataTestId ??
    (typeof label === 'string' ? `inputDisplayMode_${label.toLowerCase()}` : '')
  // Use value instead of label for test id
  // https://simplesystem.atlassian.net/browse/S2NG-6792

  return (
    <DisplayInfoRowContainer
      alignItems={alignItems}
      padding={isButtonDisplayed ? '8px 0' : '16px 0'}
      data-testid={testId}
      withoutTopBorder={withoutTopBorder}
      withoutBottomBorder={withoutBottomBorder}
    >
      <Typography
        textStyle="textNormalSb"
        whiteSpace={shouldNotWrapLabel ? 'nowrap' : undefined}
      >
        {label}
      </Typography>

      <Flex gridGap="20px" alignItems="center">
        <Typography
          textAlign={textAlign}
          style={
            !allowContentWrapping
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : {}
          }
        >
          {value || '–'}
        </Typography>

        {isButtonDisplayed && (
          <CopyButton
            copyValue={String(value)}
            style={{ whiteSpace: 'nowrap', flexShrink: 0 }}
          />
        )}
      </Flex>

      {icon && <Flex>{icon}</Flex>}
    </DisplayInfoRowContainer>
  )
}
