import { Flex, LinkBox, LinkOverlay } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'
import { FAQ_LINK, VENDOR_FAQ_LINK } from 'consts/envVars'

import { Typography } from 'app/components/Typography'
import { MailOpenIcon, OpenInNewIcon, SupportIcon } from 'app/components/icons'
import { Divider } from 'app/components/Divider'
import { MenuItemWrapper } from 'app/components/ActionsMenu/styles'
import { useCompanyCountryConfig } from 'app/containers/common/Profile'
import { useCurrentUser } from 'app/containers/common/Auth'

import { LinkBoxStyled, TextPaddingWrapper } from './styles'
import { SupportAvailabilityStatusTag } from './SupportAvailabilityStatusTag'

const messages = translations.header.contactSupport

type SupportPopoverContentProps = {
  withTitle?: boolean
}

export const SupportPopoverContent = ({
  withTitle,
}: SupportPopoverContentProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } = useCompanyCountryConfig()
  const { isVendor } = useCurrentUser()

  return (
    <Flex direction="column" gap="8px">
      <TextPaddingWrapper>
        {withTitle && (
          <Typography textStyle="headerH4">{t(messages.title)}</Typography>
        )}
        <Typography color="contentB">{t(messages.description)}</Typography>
      </TextPaddingWrapper>

      <LinkBoxStyled>
        <MailOpenIcon color={colors.contentA} />
        <LinkOverlay href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
          {SUPPORT_EMAIL}
        </LinkOverlay>
      </LinkBoxStyled>

      <LinkBoxStyled>
        <SupportIcon color={colors.contentA} />
        <LinkOverlay href={`tel:${SUPPORT_PHONE_NUMBER}`} target="_blank">
          {SUPPORT_PHONE_NUMBER}
        </LinkOverlay>
        <SupportAvailabilityStatusTag />
      </LinkBoxStyled>

      <TextPaddingWrapper>
        <Typography textStyle="textSmall" color="contentB">
          {t(messages.phoneSupport.description)}
        </Typography>
      </TextPaddingWrapper>

      <Divider py="4px" />

      <TextPaddingWrapper>
        {withTitle && (
          <Typography textStyle="headerH4">
            {t(messages.helpCenter.header)}
          </Typography>
        )}
      </TextPaddingWrapper>

      <LinkBox>
        <MenuItemWrapper>
          <Flex gap="16px">
            <OpenInNewIcon />

            <LinkOverlay
              href={isVendor ? VENDOR_FAQ_LINK : FAQ_LINK}
              target="_blank"
            >
              <Typography>{t(messages.helpCenter.faq)}</Typography>
            </LinkOverlay>
          </Flex>
        </MenuItemWrapper>
      </LinkBox>
    </Flex>
  )
}
