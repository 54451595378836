import styled from '@emotion/styled'

import type { ButtonProps } from '../types'

import {
  makeMediumButtonDimensionStyles,
  smallButtonDimensionStyles,
  squareMediumButtonDimensionStyles,
  squareSmallButtonDimensionStyles,
} from './dimensions'
import { ButtonStyledComponent } from './ButtonStyledComponent'

export const ButtonStyled = styled(ButtonStyledComponent)<ButtonProps>(
  ({
    color,
    isTextFullWidth,
    isFlatRight,
    isFlatLeft,
    shouldDisplayOnPrintout,
    theme: { colors, textStyles, mediaQuery },
  }) => ({
    ...textStyles.inputMediumButtonSb,
    color: color ? colors[color] : colors.contentA,
    pointerEvents: 'all',
    borderRadius: '6px',
    ...(isFlatLeft && {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
    }),
    ...(isFlatRight && {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
    }),
    ':focus': {
      boxShadow: 'none',
    },
    ':disabled': {
      color: colors.contentOff,
    },
    ...(isTextFullWidth && {
      '> .chakra-text': {
        width: '100%',
      },
    }),
    [mediaQuery.print]: {
      display: shouldDisplayOnPrintout ? 'initial' : 'none',
    },
  }),
  ({ size, isTallOnMobile }) => {
    switch (size) {
      case 'sm': {
        return smallButtonDimensionStyles
      }
      case 'md':
        return makeMediumButtonDimensionStyles({
          isTallOnMobile,
        })
      case 'squareSm': {
        return squareSmallButtonDimensionStyles
      }
      case 'squareMd': {
        return squareMediumButtonDimensionStyles
      }
    }
  },
  ({ variant, color, theme: { colors, borders } }) => {
    switch (variant) {
      case 'primary':
        return {
          color: color ?? colors.white,
          background: colors.blue_500,
          ':disabled, :hover:disabled': {
            background: colors.backgroundC,
            '&[data-ischecked="true"]': {
              color: colors.white,
              background: colors.contentB,
            },
          },
          ':hover:not([disabled]):not(:active)': {
            background: colors.blue_400,
          },
          ':active': {
            background: colors.blue_600,
          },
        }
      case 'secondary':
        return {
          background: colors.backgroundC,
          ':disabled, :hover:disabled': {
            background: colors.backgroundC,
          },
          ':hover:not([disabled]):not(:active)': {
            background: colors.backgroundD,
          },
          ':active': {
            background: colors.backgroundE,
          },
        }
      case 'tertiary':
        return {
          border: borders.primary,
          background: 'transparent',
          ':disabled, :hover:disabled': {
            background: 'transparent',
            color: colors.contentOff,
            opacity: 1,
          },
          ':hover:not([disabled]):not(:active)': {
            border: `1px solid ${colors.lineDarker}`,
            background: 'transparent',
          },
          ':active': {
            background: colors.backgroundC,
          },
        }
      case 'red':
        return {
          color: color ?? colors.white,
          background: colors.red_500,
          ':disabled, :hover:disabled': {
            background: colors.backgroundC,
          },
          ':hover:not([disabled]):not(:active)': {
            background: colors.red_400,
          },
          ':active': {
            background: colors.red_600,
          },
        }
      case 'error':
        return {
          background: colors.red_100,
          ':disabled, :hover:disabled': {
            background: colors.backgroundC,
          },
        }
      case 'white':
        return {
          border: borders.primary,
          background: colors.white,
          ':disabled, :hover:disabled': {
            background: colors.white,
          },
          ':hover:not([disabled]):not(:active)': {
            border: `1px solid ${colors.lineDarker}`,
            background: colors.white,
          },
          ':active': {
            background: colors.backgroundC,
          },
        }
      case 'success':
        return {
          color: color ?? colors.white,
          background: colors.green_500,
          ':disabled, :hover:disabled': {
            background: colors.green_500,
          },
          ':hover:not([disabled]):not(:active)': {
            background: colors.green_500,
          },
          ':active': {
            background: colors.green_500,
          },
        }
    }
  },
)
