import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import type { FlexProps, StackProps } from '@chakra-ui/react'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { css } from '@emotion/react'

import type { Theme } from 'theme'

import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'

export const SIDE_NAV_WIDTH_RAW = 264
export const SIDE_NAV_WIDTH = `${264}px` as const

export const NavWrapper = styled(Box)(({ theme: { borders } }) => ({
  width: SIDE_NAV_WIDTH,
  flexShrink: 0,
  position: 'relative',
}))

export const Nav = styled(
  ({ pageOffsetTop, as, ...props }: FlexProps & { pageOffsetTop: string }) => (
    <Flex as={as} {...props} />
  ),
)(({ pageOffsetTop, theme }) => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'fixed',
  height: `calc(100% - ${pageOffsetTop})`,
  width: SIDE_NAV_WIDTH,
  padding: '56px 16px 24px 16px',
  overflow: 'auto',
  borderRight: theme.borders.primary,
}))

export const Heading = styled((props: TypographyProps) => (
  <Typography textStyle="textSmallLink" {...props} />
))(({ theme: { colors } }) => ({
  fontWeight: 'bolder',
  textTransform: 'uppercase',
  marginLeft: '8px',
  marginBottom: '16px',
  color: colors.contentB,
}))

export const NavList = styled((props: StackProps) => (
  <Stack spacing="4px" {...props} />
))(({ theme: { colors } }) => ({
  '.active': {
    color: colors.blue_500,
    background: colors.backgroundC,
    ':hover': {
      background: colors.backgroundD,
    },
  },
}))

export const commonNavItemStyles = ({
  theme: { textStyles, colors },
}: {
  theme: Theme
}) =>
  css({
    ...textStyles.inputNormalSb,
    color: colors.contentA,
    display: 'flex',
    alignItems: 'flex-end',
    height: '40px',
    padding: '10px 12px',
    borderRadius: '4px',
    ':hover': {
      background: colors.backgroundC,
    },
  })

export const NavItem = styled(NavLink)(({ theme }) =>
  commonNavItemStyles({ theme }),
)
