import { useMutation } from '@tanstack/react-query'

import { api } from 'apiServices'

import { currentCartScope } from '../consts'
import type { UpdateAccountRequestArgs } from '../types'
import { useCartMutationOptions } from '../useCartMutationOptions'
import { useAccountingAbsentOnError } from '../useAccountingAbsentOnError'

export type API_UpdateCartRequestArgs = Partial<{
  account: UpdateAccountRequestArgs
  generalLedgerId: string | null
  additionalAccountingInfo: string | null
  orderComment: string | null
}>

export const url = currentCartScope

const updateCartRequest = (params: API_UpdateCartRequestArgs) =>
  api.patch<unknown, undefined>(url, params)

export const useUpdateCartMutation = () => {
  const cartMutationOptions = useCartMutationOptions()
  const accountingAbsentOnError = useAccountingAbsentOnError()

  return useMutation({
    mutationFn: updateCartRequest,
    ...cartMutationOptions,
    onError: (error: unknown) => {
      cartMutationOptions.onError(error)
      accountingAbsentOnError(error)
    },
  })
}
