import { PATHS } from 'consts/paths'
import { translations } from 'locales/translations'

const messages = translations.pages.customer.breadcrumbs

export const BREADCRUMBS_MAP = {
  [PATHS.customer.addUser]: [
    { labelTranslation: messages.users },
    { labelTranslation: messages.addNewUser },
  ],
  [PATHS.customer.editUser]: [
    { labelTranslation: messages.users },
    { labelTranslation: messages.editUser },
  ],
  [PATHS.customer.requestNewVendor]: [
    { labelTranslation: messages.vendors },
    { labelTranslation: messages.requestNewVendor },
  ],
  [PATHS.customer.switchAccount]: [
    { labelTranslation: messages.simpleSystem },
    { labelTranslation: messages.changeCompany },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToAccountType]: [
    { labelTranslation: messages.accountTypeAndValues },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToAccountValue]: [
    { labelTranslation: messages.accountTypeAndValues },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToGeneralLedger]: [
    { labelTranslation: messages.generalLedgers },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.addApprovalFlow]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.addNewFlow },
  ],
  [PATHS.customer.companyAndSettings.editApprovalFlow]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.editApprovalFlow },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToApprovalFlow]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToUserGroup]: [
    { labelTranslation: messages.userManagement },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.shareArticleList]: [
    { labelTranslation: messages.articleLists },
    { labelTranslation: messages.shareArticleListWithOthers },
  ],
  [PATHS.customer.companyAndSettings.articleListsCollaborate]: [
    { labelTranslation: messages.articleLists },
    { labelTranslation: messages.articleListCollaborate },
  ],
  [PATHS.customer.companyAndSettings.articleListGenerateBarcodes]: [
    { labelTranslation: messages.articleLists },
    { labelTranslation: messages.articleListGenerateBarcodes },
  ],
  [PATHS.customer.companyAndSettings.assignDeliveryAddress]: [
    { labelTranslation: messages.deliveryAddresses },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.deliveryAddressManagePermissions]: [
    { labelTranslation: messages.deliveryAddresses },
    { labelTranslation: messages.managePermissions },
  ],
  [PATHS.customer.companyAndSettings.createBudget]: [
    { labelTranslation: messages.budgetManagement },
    { labelTranslation: messages.createBudget },
  ],
  [PATHS.customer.companyAndSettings.editBudget]: [
    { labelTranslation: messages.budgetManagement },
    { labelTranslation: messages.editBudget },
  ],
  [PATHS.customer.companyAndSettings.createPriceRestriction]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.createPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.createDefaultPriceRestriction]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.createDefaultPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.editPriceRestriction]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.editPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.editDefaultPriceRestriction]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.editDefaultPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToPriceRestriction]: [
    { labelTranslation: messages.priceRestrictions },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.createCatalogView]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.createCatalogView },
  ],
  [PATHS.customer.companyAndSettings.editCatalogView]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.editCatalogView },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToCatalogView]: [
    { labelTranslation: messages.catalogView },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.createDefaultCatalogView]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.createDefaultCatalogView },
  ],
  [PATHS.customer.companyAndSettings.editDefaultCatalogView]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.editDefaultCatalogView },
  ],
  [PATHS.customer.companyAndSettings.accountingSettings]: [
    { labelTranslation: messages.typesAndValues },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.ordersManageUserPrivileges]: [
    { labelTranslation: messages.orderHistory },
    { labelTranslation: messages.userPrivileges },
  ],
  [PATHS.customer.companyAndSettings.deliveriesAndPaymentsManageUserPrivileges]:
    [
      { labelTranslation: messages.deliveriesAndPayments },
      { labelTranslation: messages.userPrivileges },
    ],
  [PATHS.customer.companyAndSettings.articleIndividualisationUserPrivileges]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.userPrivileges },
  ],
  [PATHS.customer.companyAndSettings.createCustomerArticleNumbersList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.createCustomerArticleNumber },
  ],
  [PATHS.customer.companyAndSettings.editCustomerArticleNumbersList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.editCustomerArticleNumber },
  ],
  [PATHS.customer.scanner]: [
    { labelTranslation: messages.home },
    { labelTranslation: messages.barcodeScanner },
  ],
  [PATHS.customer.fastOrder]: [
    { labelTranslation: messages.home },
    { labelTranslation: messages.fastOrder },
  ],
  [PATHS.customer.companyAndSettings.createCoreArticleList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.createCoreArticleList },
  ],
  [PATHS.customer.companyAndSettings.editCoreArticleList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.editCoreArticleList },
  ],
  [PATHS.customer.companyAndSettings.createIndividualMapping]: [
    { labelTranslation: messages.erpIntegration },
    { labelTranslation: messages.createIndividualMapping },
  ],
  [PATHS.customer.companyAndSettings.editIndividualMapping]: [
    { labelTranslation: messages.erpIntegration },
    { labelTranslation: messages.editIndividualMapping },
  ],
  [PATHS.customer.companyAndSettings.approvalFlowsSettings]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.settings },
  ],
  [PATHS.customer.companyAndSettings.createVendingMachine]: [
    { labelTranslation: messages.erpIntegration },
    { labelTranslation: messages.createVendingMachine },
  ],
  [PATHS.customer.companyAndSettings.editVendingMachine]: [
    { labelTranslation: messages.erpIntegration },
    { labelTranslation: messages.editVendingMachine },
  ],
  [PATHS.customer.companyAndSettings.vendingMachineDetails]: [
    { labelTranslation: messages.erpIntegration },
    { labelTranslation: messages.vendingMachineDetails },
  ],
  [PATHS.customer.companyAndSettings.createCartRestriction]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.createCartRestriction },
  ],
  [PATHS.customer.companyAndSettings.editCartRestriction]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.editCartRestriction },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToCartRestriction]: [
    { labelTranslation: messages.shopperAccessControl },
    { labelTranslation: messages.assignUsers },
  ],
} as const

export const WIDER_MOBILE_SPACING_PAGES_LIST = [
  PATHS.customer.search,
  PATHS.customer.vendors,
  ...Object.values(PATHS.customer.checkout),
  PATHS.customer.platformOrderDetails,
  PATHS.customer.erpGeneratedOrderDetails,
  PATHS.customer.ociTransferredCartDetails,
]
