import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { ShippingIcon } from 'app/components/icons'
import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

const messages = translations.pages.customer.search.searchArticle

type DeliveryTimeProps = TypographyProps & {
  deliveryTime: number
}

export const DeliveryTime = ({ deliveryTime, ...rest }: DeliveryTimeProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  return (
    <Typography
      color="contentB"
      display="flex"
      gap="4px"
      alignItems="center"
      alignSelf="flex-start"
      {...rest}
    >
      <ShippingIcon />

      <span>
        {t(
          isMobile
            ? translations.words.days
            : messages.deliveryTimeInDaysFootnote,
          { count: deliveryTime },
        )}
      </span>
    </Typography>
  )
}
