import type { StackProps } from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'
import styled from '@emotion/styled'

import type { EmptyCaseWrapperProps } from './EmptyCaseWrapper'

type EmptyCaseContainerProps = StackProps &
  Pick<EmptyCaseWrapperProps, 'shouldHideBorder'>

export const EmptyCaseContainer = styled(
  ({ shouldHideBorder, ...rest }: EmptyCaseContainerProps) => (
    <VStack
      p="40px 24px"
      spacing="32px"
      width="100%"
      textAlign="center"
      {...rest}
    />
  ),
)(({ theme, shouldHideBorder }) =>
  shouldHideBorder
    ? undefined
    : { border: theme.borders.primary, borderRadius: '6px' },
)
