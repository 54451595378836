import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import {
  useOciSession,
  useCurrentUser,
  useLogoutWithRedirectToRoot,
} from 'app/containers/common/Auth'
import { ActionItem } from 'app/components/ActionsMenu/styles'
import {
  ActionDivider,
  ActionsMenu,
} from 'app/components/ActionsMenu/ActionsMenu'
import { LanguageAccordion } from 'app/components/layout/chooseLanguage'

import { ChangeCompanyActions } from './components'
import { AccountMenuUserAndCompanyInfo } from './components/AccountMenuUserAndCompanyInfo'

export const CompanyAccountMenu = ({
  triggerButton,
  additionalMenuOptions,
  isLogoutAvailable,
  areCompanyDetailsHidden,
}: {
  triggerButton: ReactNode
  additionalMenuOptions?: ReactNode
  isLogoutAvailable: boolean
  areCompanyDetailsHidden?: boolean
}) => {
  const { t } = useTranslation()
  const { mutate: logout } = useLogoutWithRedirectToRoot()
  const { isCustomer } = useCurrentUser()
  const { isOciSessionFlow } = useOciSession()
  const history = useHistory()

  return (
    <div style={{ zIndex: 2 }}>
      <ActionsMenu
        menuButton={triggerButton}
        contentWidth="320px"
        dataTestId="userMenu"
        isWithoutPortal
      >
        <AccountMenuUserAndCompanyInfo
          areCompanyDetailsHidden={areCompanyDetailsHidden}
        />
        {!isOciSessionFlow && isCustomer && (
          <ActionItem
            onClick={() =>
              history.push(PATHS.customer.companyAndSettings.deliveryAddresses)
            }
          >
            {t(translations.header.deliveryAddresses)}
          </ActionItem>
        )}
        <ActionDivider />

        <ChangeCompanyActions ButtonComponent={ActionItem} />

        {!!additionalMenuOptions && (
          <>
            {additionalMenuOptions}
            <ActionDivider />
          </>
        )}

        <LanguageAccordion />

        {isLogoutAvailable && (
          <>
            <ActionDivider />
            <ActionItem onClick={() => logout()} data-testid="logOut">
              {t(translations.header.logout)}
            </ActionItem>
          </>
        )}
      </ActionsMenu>
    </div>
  )
}
