import i18next from 'i18next'
import type { Dinero, Currency } from 'dinero.js'
import { dinero, toDecimal } from 'dinero.js'
import * as currencies from '@dinero.js/currencies'

import type { MODEL__MonetaryAmount } from 'app/models/monetaryAmount'
import { throwIfUnsupportedCurrency } from 'app/hooks/useCurrency'

// create intl formatter for dinero price formatting
// src: https://v2.dinerojs.com/docs/getting-started/upgrade-guide#dropped-support-for-locale
function createIntlFormatter(locale: string, options = {}) {
  function transformer({
    value,
    currency,
  }: {
    value: string
    currency: Currency<number>
  }) {
    return Number(value).toLocaleString(locale, {
      ...options,
      style: 'currency',
      currency: currency.code,
    })
  }

  return function formatter(dineroObject: Dinero<number>) {
    return toDecimal(dineroObject, transformer)
  }
}

export const formatMonetaryAmount = (price: MODEL__MonetaryAmount) => {
  throwIfUnsupportedCurrency(price.currency)

  const dineroPrice = dinero({
    amount: price.amount,
    currency: currencies[price.currency],
  })

  return createIntlFormatter(i18next.language)(dineroPrice)
}

export const getCurrencySymbol = (currency: string | undefined) => {
  if (!currency) return

  const formatter = new Intl.NumberFormat(i18next.language, {
    style: 'currency',
    currency,
  })

  const symbol = formatter
    .formatToParts(0)
    .find(({ type }) => type === 'currency')?.value

  return symbol
}

export const formatFloatToMinors = (value?: number) =>
  parseInt(((value ?? 0) * 100).toFixed(2), 10)

export const formatMinorsToFloat = (value?: number) => (value || 0) / 100
