import { Flex } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { UserIcon } from 'app/components/icons'
import { Button } from 'app/components/Buttons/Button'
import { ActionItem } from 'app/components/ActionsMenu/styles'
import {
  ActionDivider,
  ActionsMenu,
} from 'app/components/ActionsMenu/ActionsMenu'
import { LanguageAccordion } from 'app/components/layout/chooseLanguage'
import { tokenEmailSelector } from 'app/containers/common/Auth/selectors'
import { Typography } from 'app/components/Typography'
import { useLogoutWithRedirectToRoot } from 'app/containers/common/Auth'

export const BackofficeAccountMenu = ({
  children,
}: {
  children: ReactNode
}) => {
  const { t } = useTranslation()
  const { mutate: logout } = useLogoutWithRedirectToRoot()
  const email = useSelector(tokenEmailSelector)

  return (
    <ActionsMenu
      menuButton={children}
      contentWidth="320px"
      dataTestId="userMenu"
      isWithoutPortal
    >
      <Flex padding="8px 12px 16px 12px">
        <Typography textStyle="headerH4">{email}</Typography>
      </Flex>

      <ActionDivider />

      <LanguageAccordion />
      <ActionDivider />
      <ActionItem onClick={() => logout()}>
        {t(translations.header.logout)}
      </ActionItem>
    </ActionsMenu>
  )
}

export const BackofficeAccountButton = () => {
  const email = useSelector(tokenEmailSelector)

  return (
    <BackofficeAccountMenu>
      <Button size="sm" variant="secondary" leftIcon={<UserIcon />}>
        {email}
      </Button>
    </BackofficeAccountMenu>
  )
}
