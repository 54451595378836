import type { IconProps } from '../types'

export const ShippingIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M3.2 8a2 2 0 0 1 2-2h8v8h-10V8Z" fill={color} />
    <circle cx={6.2} cy={14} r={1.5} fill="#fff" stroke={color} />
    <path fill={color} d="M12.2 11h5v3h-5z" />
    <circle cx={14.2} cy={14} r={1.5} fill="#fff" stroke={color} />
    <path d="M12.7 8.5h2.232l1.768 2.651v.349h-4v-3Z" stroke={color} />
  </svg>
)
