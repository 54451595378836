/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReactReduxProvider } from 'react-redux'
import FontFaceObserver from 'fontfaceobserver'
import { HelmetProvider } from 'react-helmet-async'
import { Router } from 'react-router-dom'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import queryString from 'query-string'
import { QueryParamProvider } from 'use-query-params'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ChakraProvider } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import NiceModal from '@ebay/nice-modal-react'
import TagManager from 'react-gtm-module'
import { CacheProvider } from '@emotion/react'
import { PersistGate } from 'redux-persist/integration/react'

// Use consistent styling
import 'sanitize.css/sanitize.css'
// Initialize languages
// We need to have it on top, to make it accessible ASAP
import './locales/i18n'

import { history, store, persistor } from 'store'
import { theme } from 'theme'
import {
  GTM_AUTH,
  GTM_ID,
  GTM_PREVIEW,
  IS_TEST,
  MOCK_SERVICE_WORKER_ENABLED,
  SENTRY_DSN,
  SENTRY_ENV,
} from 'consts/envVars'
import { FONT_FETCH_TIMEOUT } from 'consts/misc'
import { initializeSentry } from 'sentry'

import { STORAGE_APP_VERSION_KEY } from 'app/App/utils'

// Import root app component
import { App } from './app/App'
import { queryClient } from './queryClient/queryClient'
import { ToastProvider } from './app/providers/ToastProvider'

if (!(IS_TEST || window.Cypress) && SENTRY_ENV && SENTRY_DSN) {
  const version = window.localStorage.getItem(STORAGE_APP_VERSION_KEY)

  initializeSentry({ dsn: SENTRY_DSN, environment: SENTRY_ENV, version })
}

// GTM
if (!(IS_TEST || window.Cypress) && GTM_ID && GTM_AUTH && GTM_PREVIEW) {
  TagManager.initialize({
    gtmId: GTM_ID,
    auth: GTM_AUTH,
    preview: GTM_PREVIEW,
  })
}

// Observe loading of webfonts i.e. Source Sans Pro (to remove 'Source Sans Pro', remove the <link> tag in
// the index.html file and this observer)
const fontFaceObserver = new FontFaceObserver('Source Sans Pro', {})

// When Source Sans Pro is loaded, add a font-family using Inter to the body
fontFaceObserver.load(null, FONT_FETCH_TIMEOUT).then(
  () => document.body.classList.add('fontLoaded'),
  (error) => console.error(`Font failed to load. ${error}`),
)

const prepare = async () => {
  if (MOCK_SERVICE_WORKER_ENABLED) {
    const { initMockServiceWorker } = await import('./utils/testing/worker')

    initMockServiceWorker()
  }
}

// INFO: needed to resolve https://github.com/emotion-js/emotion/issues/1105
const emotionCache = createCache({ key: 'emotion-cache' })
emotionCache.compat = true

const MOUNT_NODE = document.getElementById('root') as HTMLElement
const root = createRoot(MOUNT_NODE)

// INFO: some changes here probably should be reflected in the utils/testing/renderTestComponent/Providers.tsx
prepare().then(() => {
  if (!persistor) throw new Error('persistor should be defined')

  root.render(
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={theme}>
        <ReactReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                  <QueryParamProvider
                    adapter={ReactRouter5Adapter}
                    options={{
                      // TODO: migrate to URLSearchParams aka drop this prop altogether https://simplesystem.atlassian.net/browse/S2NG-2639
                      searchStringToObject: queryString.parse,
                      objectToSearchString: queryString.stringify,
                    }}
                  >
                    <ToastProvider>
                      <NiceModal.Provider>
                        <React.StrictMode>
                          <App />
                          <ReactQueryDevtools position="bottom" />
                        </React.StrictMode>
                      </NiceModal.Provider>
                    </ToastProvider>
                  </QueryParamProvider>
                </QueryClientProvider>
              </HelmetProvider>
            </Router>
          </PersistGate>
        </ReactReduxProvider>
      </ChakraProvider>
    </CacheProvider>,
  )
})
