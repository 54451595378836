import { colors } from 'theme/colors'

import { textStyles } from '../Typography'

import type { Size } from './types'

export const makeSizeDependentStyles = (size: Size = 'md') => {
  switch (size) {
    case 'sm': {
      return {
        height: '36px',
        ...textStyles.textNormal,
      }
    }
    case 'md': {
      return {
        height: '48px',
        ...textStyles.inputNormal,
      }
    }
  }
}

export const disabledStateStyles = {
  ':disabled, :hover:disabled, div[data-disabled="true"], div[data-disabled="true"]:hover':
    {
      border: 'none',
      cursor: 'not-allowed',
      color: colors.contentOff,
      background: colors.backgroundB,
      opacity: 1,

      '.left-icon': {
        opacity: 0.2,
      },
    },
}

export const readOnlyStateStyles = {
  ':read-only, :hover:read-only': {
    border: `1px solid ${colors.line}`,
    cursor: 'default',
    background: 'unset',
    color: colors.contentA,
  },
}

export const makeCommonInputStyles = (isError = false) => ({
  borderRadius: '6px',
  border: 'none',
  color: isError ? colors.red_500 : colors.contentA,
  background: isError ? colors.red_100 : colors.backgroundC,

  ':hover:not([disabled]), :focus, :active': {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: isError ? colors.red_100 : colors.backgroundC,
  },
})
