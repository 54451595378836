import { Box, useRadio } from '@chakra-ui/react'
import { Fragment } from 'react'

import { colors } from 'theme/colors'

import { Typography } from 'app/components/Typography'

import { RadioOptionStyled, RadioStyled } from './styles'
import type { RadioOptionProps } from './types'

export const RadioOption = ({
  Wrapper,
  children,
  withRadioFirst,
  orientation,
  justifyContent,
  alignItems,
  flex,
  borderRadius,
  padding,
  dataTestId,
  ...radioProps
}: RadioOptionProps) => {
  // Deprecated getCheckboxProps - https://simplesystem.atlassian.net/browse/S2NG-7322
  const { getInputProps, getCheckboxProps } = useRadio(radioProps)

  const { isDisabled, isChecked } = radioProps

  const WrapperComponent = Wrapper ?? Fragment

  return (
    <WrapperComponent>
      <Box
        as="label"
        flex={flex ?? (orientation === 'horizontal' ? '1' : 'initial')}
        data-testid={dataTestId}
        data-checked={isChecked}
      >
        <input {...getInputProps()} />

        <RadioOptionStyled
          withRadioFirst={withRadioFirst}
          orientation={orientation}
          padding={padding}
          borderRadius={borderRadius}
          alignItems={alignItems}
          justifyContent={justifyContent}
          {...getCheckboxProps()}
        >
          <Typography
            overflow="hidden"
            textOverflow="ellipsis"
            flex={1}
            as="div"
            textColor={isDisabled ? colors.contentOff : colors.contentA}
          >
            {children}
          </Typography>

          <RadioStyled checked={radioProps.isChecked} isDisabled={isDisabled} />
        </RadioOptionStyled>
      </Box>
    </WrapperComponent>
  )
}
