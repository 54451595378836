import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'

import { useCheckSupportTimeAvailability } from './useCheckSupportTimeAvailability'

const messages = translations.header.contactSupport.phoneSupport

export const SupportAvailabilityStatusTag = () => {
  const { t } = useTranslation()
  const { isValid, isAvailable } = useCheckSupportTimeAvailability()

  if (!isValid) return null

  return (
    <Flex width="100%" flex={1} justifyContent="flex-end">
      <Typography
        textStyle="textSmallSb"
        color={isAvailable ? 'green_600' : 'red_500'}
        textTransform="uppercase"
      >
        {isAvailable ? t(messages.available) : t(messages.unavailable)}
      </Typography>
    </Flex>
  )
}
