export const accountTypesScope = 'account-types'
export const assignedAccountTypesScope = 'assigned-account-types'
export const accountTypePath = `${accountTypesScope}/:accountTypeId` as const

export const accountType409ErrorResponse = {
  message: 'Prefix already used by this customer',
  details: [
    {
      field: 'prefix',
      message: 'Prefix must be unique',
    },
  ],
  timestamp: '2022-07-06T13:10:11.233309800Z',
}
