import { generatePath, useLocation } from 'react-router-dom'

import { assertNever } from 'types/utils'
import { history } from 'libs/history'
import { PATHS } from 'consts/paths'

import type {
  ArticleListId,
  ArticlesListAccessLevel,
} from 'app/containers/customers/ArticleLists/types'

type PushedFrom = {
  pushedFrom: 'LIST' | 'DETAILS'
}

type GoToPageProps = PushedFrom & {
  id: ArticleListId
}

export const goToInviteToCollaboratePage = ({
  id,
  pushedFrom,
}: GoToPageProps) => {
  history.push({
    pathname: generatePath(
      PATHS.customer.companyAndSettings.articleListsCollaborate,
      {
        articleListId: id,
      },
    ),
    state: { pushedFrom },
  })
}

export const goToShareWithOthersPage = ({ id, pushedFrom }: GoToPageProps) => {
  history.push({
    pathname: generatePath(PATHS.customer.companyAndSettings.shareArticleList, {
      articleListId: id,
    }),
    state: { pushedFrom },
  })
}

export const goToArticleListDetailsPage = (id: ArticleListId) => {
  history.push(
    generatePath(PATHS.customer.companyAndSettings.articleListDetails, {
      articleListId: id,
    }),
  )
}

export const useArticleListOnSuccessRedirect = (id: ArticleListId) => {
  const location = useLocation<PushedFrom | undefined>()
  const { pushedFrom } = location.state || {}

  return () => {
    if (!pushedFrom || pushedFrom === 'LIST') {
      return history.push(PATHS.customer.companyAndSettings.articleLists)
    }

    return goToArticleListDetailsPage(id)
  }
}

export const getArticlesListAccessLevel = (
  accessLevel: ArticlesListAccessLevel,
) => {
  switch (accessLevel) {
    case 'ADMIN':
      return { isOwner: true }
    case 'WRITE':
      return { isCollaborator: true }
    case 'READ':
      return { isViewer: true }
    default:
      assertNever(accessLevel)
  }
}
