import { Box } from '@chakra-ui/react'

import { DeliveryTime } from '../../ArticleTexts'
import type { ArticleContentProps } from '../types'

export const GridViewArticleDetails = ({
  article,
}: Pick<ArticleContentProps, 'article'>) => (
  <Box mt="16px">
    <DeliveryTime textStyle="textSmall" deliveryTime={article.deliveryTime} />
  </Box>
)
