import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { CopyIcon } from 'app/components/icons/components/CopyIcon'

import { Button } from '../Button'
import { useNotificationSnackbar } from '../../Notification/NotificationToast'

import type { CopyButtonProps } from './types'
import { IconOnlyCopyButtonWrapper } from './styles'

export const CopyButton = ({
  children,
  copyValue,
  iconOnly,
  ...rest
}: CopyButtonProps) => {
  const { t } = useTranslation()
  const { enqueueNotificationSnackbar } = useNotificationSnackbar()

  const handleCopyButtonClick = async () => {
    // INFO: try catch because chrome issues https://medium.com/nerd-for-tech/navigator-clipboard-api-b96be187df5d
    try {
      await navigator.clipboard.writeText(copyValue)
      enqueueNotificationSnackbar({
        notificationContent: t(translations.formActions.copySuccessToast, {
          value: copyValue,
        }),
        notificationProps: {
          type: 'success',
        },
      })
    } catch (error) {
      if (!(error instanceof Error)) throw error

      if (!error.message.includes('Document is not focused')) throw error // do not throw for this specific error
    }
  }

  if (iconOnly) {
    return (
      <IconOnlyCopyButtonWrapper onClick={handleCopyButtonClick} as="span">
        <CopyIcon />
      </IconOnlyCopyButtonWrapper>
    )
  }

  return (
    <Button
      onClick={handleCopyButtonClick}
      type="button"
      variant="tertiary"
      size="sm"
      {...rest}
    >
      {children ?? t(translations.formActions.copy)}
    </Button>
  )
}
