import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from '../Typography'

import { Error } from './Error'
import type { FormControlWrapperProps } from './types'

function checkIfHasError(
  params: Pick<FormControlWrapperProps, 'touched' | 'error'>,
): params is { touched: true; error: string } {
  if (params.touched && !!params.error) {
    return true
  }

  return false
}

const optionalMessage = translations.words.optional

export const FormControlWrapper = ({
  name,
  label,
  isOptional,
  caption,
  isDisabled,
  children,
  hideErrorMessage,
  error,
  touched,
  labelAs,
}: FormControlWrapperProps) => {
  const hasError = checkIfHasError({ error, touched })

  const { t } = useTranslation()

  return (
    <FormControl data-testid={name} isInvalid={hasError}>
      {label && (
        <FormLabel htmlFor={name} mr="0" as={labelAs}>
          <HStack>
            <Typography
              textStyle="textNormalSb"
              color={isDisabled ? 'contentC' : 'contentA'}
              as={labelAs}
            >
              {label}
            </Typography>
            {isOptional && (
              <Typography textStyle="textSmall" color="contentB">
                {t(optionalMessage)}
              </Typography>
            )}
          </HStack>
        </FormLabel>
      )}

      {children}
      {hasError && !hideErrorMessage ? (
        <FormErrorMessage data-testid={`${name}_fieldError`} lineHeight="20px">
          <Error touched error={error || null} />
        </FormErrorMessage>
      ) : (
        caption && (
          <FormHelperText>
            <Typography color="contentB">{caption}</Typography>
          </FormHelperText>
        )
      )}
    </FormControl>
  )
}
