import { forwardRef } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import { useTheme } from 'theme'

import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'

type PopoverButtonProps = {
  isOpen: boolean
  withoutArrow?: boolean
  size?: ButtonProps['size']
  arrowColor?: string
} & ButtonProps

export const PopoverButton = forwardRef<HTMLButtonElement, PopoverButtonProps>(
  ({ isOpen, withoutArrow, size = 'sm', arrowColor, ...rest }, ref) => {
    const { colors } = useTheme()

    const EndEnhancer = isOpen ? ChevronUpIcon : ChevronDownIcon
    const rightIcon = withoutArrow ? undefined : (
      <EndEnhancer boxSize="20px" color={arrowColor ?? colors.contentC} />
    )

    return (
      <Button
        ref={ref}
        variant="secondary"
        size={size}
        rightIcon={rightIcon}
        {...rest}
      />
    )
  },
)
