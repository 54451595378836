import { useSelector } from 'react-redux'

import { useTheme } from 'theme'

import { baseIdentitySelector } from 'app/containers/common/Auth/selectors'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useCurrentUser } from 'app/containers/common/Auth'

import { useMobileSearchInputVisibility } from './components/Header/CustomerNav/useMobileSearchInputVisibility'

const useHeaderHeight = () => {
  const { dimensionsRaw } = useTheme()
  const { isMobile, isTablet } = useBreakpointScreenSize()
  const { isSearchInputVisible } = useMobileSearchInputVisibility()
  const { isCustomer, isOciCustomer } = useCurrentUser()

  if (isMobile)
    return isSearchInputVisible
      ? dimensionsRaw.mobileHeaderHeightWithSearch
      : dimensionsRaw.mobileHeaderHeight

  if (isCustomer || isOciCustomer) {
    if (isTablet) return dimensionsRaw.headerHeight

    return dimensionsRaw.customerDesktopHeaderHeight
  }

  return dimensionsRaw.headerHeight
}

export const usePageOffsetTop = () => {
  const { dimensionsRaw } = useTheme()
  const headerHeight = useHeaderHeight()
  const { isUserImpersonated } = useSelector(baseIdentitySelector)

  let pageOffsetTop: number = headerHeight

  if (isUserImpersonated) {
    pageOffsetTop = headerHeight + dimensionsRaw.impersonationBarHeight
  }

  return `${pageOffsetTop}px`
}
