import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { translations } from 'locales/translations'

import type { ModelSearchArticleListItem } from 'app/containers/customers/ArticleLists/Articles/search/types'
import { LinkBIcon } from 'app/components/icons'
import { Typography } from 'app/components/Typography'

type ExternalReferencesTextProps = Pick<
  ModelSearchArticleListItem['articleData'],
  'externalReferences'
>

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details

export const ExternalReferencesText = ({
  externalReferences,
}: ExternalReferencesTextProps) => {
  const { t } = useTranslation()

  const { colors } = useTheme()

  if (!externalReferences || externalReferences.length === 0) return null

  const [{ barcode: firstBarcode }, ...rest] = externalReferences

  const hasAdditionalExternalReferences = rest.length > 0

  return (
    <Flex gap="4px">
      <LinkBIcon color={colors.contentB} width="16px" height="16px" />

      <Typography textStyle="textSmall" color="contentB">
        {t(messages.externalReferences, { externalReference: firstBarcode })}{' '}
        {hasAdditionalExternalReferences && `(+${rest.length})`}
      </Typography>
    </Flex>
  )
}
