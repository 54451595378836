import { http, HttpResponse } from 'msw'

import { getCustomerMockUrl } from '../../utils'

import { url } from './hooks'

export const updateCartMock = http.patch(
  getCustomerMockUrl(url),
  () => new HttpResponse(null, { status: 204 }),
)

export const makeUpdateCartMockWithSpy = (spy: jest.Mock) =>
  http.patch(getCustomerMockUrl(url), async ({ request }) => {
    const body = await request.json()

    spy(body)

    return new HttpResponse(null, { status: 204 })
  })
