import { matchPath, useLocation } from 'react-router-dom'

import type { Paths } from 'consts/paths'
import { PATHS } from 'consts/paths'
import type { Color } from 'theme/colors'

const PAGES_WITH_BACKGROUND = [
  PATHS.customer.home,
  PATHS.customer.checkout.cart,
  PATHS.customer.checkout.address,
  PATHS.customer.checkoutSuccess,
] as Paths[]

export const usePageBackground = (): Color => {
  const { pathname } = useLocation()

  const backgroundColor = PAGES_WITH_BACKGROUND.some((path) =>
    matchPath(pathname, { path, exact: true }),
  )
    ? 'backgroundB'
    : 'white'

  return backgroundColor
}
