/*
 * Input with FormControl wrapper and useField connected from Formik
 */

import { useField } from 'formik'
import { trim } from 'lodash'
import type { ChangeEvent, FocusEvent } from 'react'
import { forwardRef } from '@chakra-ui/react'

import { DisplayInfoRow } from 'app/components/DisplayInfoRow'

import { FormControlWrapper } from '../FormControlWrapper'

import type { InputProps } from './types'
import { BareInput } from './BareInput'

export const Input = forwardRef<InputProps, 'input'>(
  (
    {
      name,
      caption,
      label,
      labelAs,
      isDisabled,
      isInDisplayMode,
      showCopyButton,
      disableTrimOnBlur,
      hideErrorMessage,
      acceptedCharacters,
      type,
      isOptional,
      withoutTopBorder,
      withoutBottomBorder,
      value: passedValue,
      ...rest
    },
    ref,
  ) => {
    const [
      { value: formikValue, onBlur, onChange, ...field },
      { touched, error },
      { setValue },
    ] = useField<InputProps['value']>({ name })
    const value = passedValue ?? formikValue
    const errorPresent = touched && error
    const isNumberField = type === 'number'

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!acceptedCharacters) return onChange(e)

      if (acceptedCharacters.test(e.target.value)) {
        onChange(e)
      }
    }

    const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
      onBlur(e)

      if (!disableTrimOnBlur) {
        const trimmedValue = trim(e.target.value)
        // since trim() always returns string, we need to convert value back to number for number fields
        const valueToSet = isNumberField ? Number(trimmedValue) : trimmedValue

        setValue(valueToSet, true)
      }
    }

    // display value without input
    if (isInDisplayMode) {
      return (
        <DisplayInfoRow
          allowContentWrapping
          label={label}
          value={value}
          showCopyButton={showCopyButton}
          withoutTopBorder={withoutTopBorder}
          withoutBottomBorder={withoutBottomBorder}
        />
      )
    }

    return (
      <FormControlWrapper
        name={name}
        label={label}
        labelAs={labelAs}
        caption={caption}
        isDisabled={isDisabled}
        touched={touched}
        error={errorPresent}
        hideErrorMessage={hideErrorMessage}
        isOptional={isOptional}
      >
        <BareInput
          ref={ref}
          type={type}
          value={value}
          isError={Boolean(errorPresent)}
          isDisabled={isDisabled}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onKeyPress={(event) => {
            // always fire input's blur before form submit
            if (rest.as !== 'textarea' && event.code === 'Enter') {
              event.currentTarget.blur()
            }
          }}
          onWheel={(event) => {
            // override default behavior of scroll wheel changing the number in number field
            if (isNumberField) event.currentTarget.blur()
          }}
          {...rest}
          {...field}
        />
      </FormControlWrapper>
    )
  },
)
