import { http, HttpResponse } from 'msw'

import { getVendorMockUrl } from 'app/containers/vendors/utils'
import { getCustomerMockUrl } from 'app/containers/customers/utils'
import {
  searchData,
  searchValuesWithVendorsList,
} from 'app/containers/common/Search'
import { generalLedgerPath } from 'app/containers/customers/GeneralLedgers/consts'

import type { API__GetProfileResponse } from '../api.types'
import { contactsScope, profileScope } from '../api'

import {
  commonCompanyRegistrationMockData,
  customerProfileContactsMockData,
  makeBusinessInfoMockData,
  vendorProfileContactsMockData,
} from './data'

export const vendorProfileGetMockData: API__GetProfileResponse = {
  businessInfo: makeBusinessInfoMockData('vendor'),
  companyRegistration: commonCompanyRegistrationMockData,
  bigLogoUrl: null,
}

export const customerProfileGetMockData: API__GetProfileResponse = {
  businessInfo: makeBusinessInfoMockData('customer'),
  companyRegistration: commonCompanyRegistrationMockData,
  bigLogoUrl: null,
}

export const customerProfileGetMock = http.get(`*/${profileScope}`, () =>
  HttpResponse.json(customerProfileGetMockData),
)

export const makeVendorProfileGetMock = (
  data?: Partial<API__GetProfileResponse>,
) =>
  http.get(`*/${profileScope}`, () =>
    HttpResponse.json({ ...vendorProfileGetMockData, ...data }),
  )

export const vendorProfileGetMock = makeVendorProfileGetMock()
export const chinaVendorProfileGetMock = makeVendorProfileGetMock({
  companyRegistration: {
    ...vendorProfileGetMockData.companyRegistration,
    countryCode: 'CN',
  },
})

export const plnVendorProfileGetMock = makeVendorProfileGetMock({
  businessInfo: {
    ...vendorProfileGetMockData.businessInfo,
    currency: 'PLN',
  },
})

export const noDac7CompletedVendorProfileGetMock = makeVendorProfileGetMock({
  businessInfo: {
    ...vendorProfileGetMockData.businessInfo,
    dac7Completed: false,
  },
})

export const customerContactsGetMock = http.get(
  getCustomerMockUrl(contactsScope),
  () => HttpResponse.json(customerProfileContactsMockData),
)

export const vendorContactsGetMock = http.get(
  getVendorMockUrl(contactsScope),
  () => HttpResponse.json(vendorProfileContactsMockData),
)

export const makeCustomerProfilePatchMockWithSpy = (spyFn?: jest.Mock) =>
  http.patch(`*/${profileScope}`, async ({ request }) => {
    if (spyFn) spyFn(await request.json())

    return new HttpResponse(null, { status: 200 })
  })
