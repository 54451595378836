import { http, HttpResponse } from 'msw'

import type { ModelGetArticleListData } from 'libs/openapiGeneratedApi/Api'
import { generatedApi } from 'apiServices'

import { getCustomerMockUrl } from '../../utils'

export const articleListDetailsMockData: ModelGetArticleListData = {
  id: '123',
  name: 'Coffee articles for Managers and C-level',
  createdAt: '2023-01-19T11:32:28.682Z',
  updatedAt: '2023-01-19T11:32:28.682Z',
  articlesCount: 12,
  vendorsCount: 4,
  createdBy: {
    name: 'Erika Mustermann',
    customerUserId: '888',
    ociUserId: undefined,
    currentUser: true,
  },
  accessLevel: 'ADMIN',
  sharedUserInfo: {
    assignedUsersCount: 10,
    allCustomerUsersAssigned: false,
    allOciUsersAssigned: false,
    userGroupsAssigned: false,
  },
}

export const makeArticleListDetailsGetMock = (
  data?: Partial<ModelGetArticleListData>,
) =>
  http.get(getCustomerMockUrl(generatedApi.customers.getArticleList.url), () =>
    HttpResponse.json({ ...articleListDetailsMockData, ...data }),
  )

export const articleListDetailsGetMock = makeArticleListDetailsGetMock()
