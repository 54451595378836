import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { EClassInfo } from 'app/pages/common/eClassInfo'

import type { ArticleContentProps } from '../types'
import { CustomerArticleNumberText, DeliveryTime } from '../../ArticleTexts'

const detailsMessages = translations.pages.customer.articleDetails

export const ListViewArticleDetails = ({
  article,
}: Pick<ArticleContentProps, 'article'>) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex flex={1} flexDirection="column" gap="4px">
        <CustomerArticleNumberText
          customerArticleNumber={article.customerArticleNumber}
        />

        <Typography textStyle="textSmall" color="contentB">
          {t(detailsMessages.manufacturerArticleNumber, {
            number: article.manufacturerArticleNumber || '—',
          })}
        </Typography>
      </Flex>

      <Flex flex={1} flexDirection="column">
        <DeliveryTime
          textStyle="textSmall"
          deliveryTime={article.deliveryTime}
        />

        <EClassInfo eclass={article.eclass} />
      </Flex>
    </>
  )
}
