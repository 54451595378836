import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { api } from 'apiServices'

import { useOciSession } from 'app/containers/common/Auth'

import { assignedAccountTypesScope } from '../consts'

import type { MODEL__AssignedAccountType } from './models'

export const url = assignedAccountTypesScope

export type API__AssignedAccountTypesResponse = {
  accountTypes: MODEL__AssignedAccountType[]
}

const getAssignedAccountTypesRequest = () =>
  api.get<unknown, API__AssignedAccountTypesResponse>(url)

export const ASSIGNED_ACCOUNT_TYPES_QUERY_KEY = 'assignedAccountTypes'

export const useAssignedAccountTypesQuery = ({
  enabled = true,
}: Pick<UseQueryOptions, 'enabled'> = {}) => {
  const { isOciSessionFlow } = useOciSession()

  return useQuery({
    queryKey: [ASSIGNED_ACCOUNT_TYPES_QUERY_KEY],
    queryFn: getAssignedAccountTypesRequest,
    staleTime: 1000 * 3,
    enabled: enabled && !isOciSessionFlow,
  })
}
