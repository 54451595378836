import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { Button } from 'app/components/Buttons/Button'
import { ScannerIcon } from 'app/components/icons'
import { MarkerTooltip } from 'app/components/tooltips'
import { useFastOrderListCountQuery } from 'app/containers/customers/FastOrders/Count'
import { Typography } from 'app/components/Typography'

import { CounterWrapper } from './styles'

const messages = translations.pages.customer.scanner

const MarkerTooltipWrapper = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  if (isMobile) {
    return <Fragment>{children}</Fragment>
  }

  return (
    <MarkerTooltip content={t(messages.buttonTooltip)} placement="left">
      {children}
    </MarkerTooltip>
  )
}

export const ScannerButton = () => {
  const history = useHistory()
  const { data } = useFastOrderListCountQuery({
    fastOrderListEnum: 'SCANNING_LIST',
  })

  const { count: numberOfScanningListItems = 0 } = data ?? {}

  return (
    <MarkerTooltipWrapper>
      <Button
        size="squareSm"
        variant="secondary"
        data-testid="scannerButton"
        onClick={() => history.push(PATHS.customer.scanner)}
      >
        <ScannerIcon />
        {numberOfScanningListItems > 0 && (
          <CounterWrapper data-testid="scanningListItemsCounter">
            <Typography textStyle="textSmallSb" color="white">
              {numberOfScanningListItems}
            </Typography>
          </CounterWrapper>
        )}
      </Button>
    </MarkerTooltipWrapper>
  )
}
