import { Box, Button as ButtonBase, forwardRef } from '@chakra-ui/react'

import { includes } from 'types/utils'

import { Typography } from 'app/components/Typography'

import type { ButtonProps } from '../types'

const smallSizes: ButtonProps['size'][] = ['sm', 'squareSm']

// forwardRef needed for proper types inside styled()(...)
export const ButtonStyledComponent = forwardRef<ButtonProps, 'button'>(
  (
    {
      isTallOnMobile,
      isFullWidth,
      width = 'max-content',
      height = 'fit-content',
      children,
      leftIcon,
      rightIcon,
      iconSpacing = '0.5rem',
      whiteSpace = 'pre-line',
      isTextFullWidth,
      isFlatLeft,
      isFlatRight,
      dataTestId,
      ...props
    },
    ref,
  ) => {
    // Handle centering Button with only icon
    // https://simplesystem.atlassian.net/browse/S2NG-6420
    const noOfLines = includes(smallSizes, props.size) ? 1 : 2

    return (
      <ButtonBase
        {...(isFullWidth ? { width: 'full' } : { width })}
        maxW={isFullWidth ? undefined : '240px'}
        height={height}
        {...(whiteSpace === 'nowrap' && { flexShrink: 0 })}
        ref={ref}
        {...props}
      >
        {!!leftIcon && (
          <Box as="span" display="inline-flex" mr={iconSpacing}>
            {leftIcon}
          </Box>
        )}

        <Typography
          noOfLines={noOfLines}
          whiteSpace={whiteSpace}
          color="currentColor"
          as="span"
          {...(noOfLines === 1 && { textAlign: 'start' })}
          {...(whiteSpace === 'nowrap' && { overflow: 'visible' })}
        >
          {children}
        </Typography>

        {!!rightIcon && (
          <Box as="span" display="inline-flex" ml={iconSpacing}>
            {rightIcon}
          </Box>
        )}
      </ButtonBase>
    )
  },
)
