import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { ModelGetBudgetsListData } from 'libs/openapiGeneratedApi/Api'

import type { AccountTypeValueId } from '../../AccountTypes/Values/models'
import type { AccountTypeId } from '../../AccountTypes/models'
import { getCustomerMockUrl } from '../../utils'

export const budgetsMockData: ModelGetBudgetsListData = {
  budgets: [
    {
      id: 'bb50273a-5200-44d4-80e1-3a150d1b4149',
      name: 'Main account types budget',
      createdBy: 'Chuck Norris',
      refreshPeriod: 'YEARLY',
      yearlyRefreshPeriodStartingFromMonth: 1,
      budgetLimit: {
        amount: 1500000,
        currency: 'GBP',
      },
      budgetLeft: {
        amount: 500000,
        currency: 'GBP',
      },
      budgetSpent: {
        amount: 1000000,
        currency: 'GBP',
      },
      additionalBudgetAmount: {
        amount: 0,
        currency: 'GBP',
      },
      additionalBudgetSpent: {
        amount: 0,
        currency: 'GBP',
      },
      additionalBudgetLeft: {
        amount: 0,
        currency: 'GBP',
      },
      totalBudgetLimit: {
        amount: 1500000,
        currency: 'GBP',
      },
      totalBudgetLeft: {
        amount: 500000,
        currency: 'GBP',
      },
      accountType: {
        id: 'bb50273a-5200-44d4-80e1-3a150d1b4149' as AccountTypeId,
        prefix: 'KHYFRFRFRREDED',
        description: 'Marketing',
      },
      accountValue: {
        id: 'bb50273a-5200-44d4-80e1-3a150d1bfwef' as AccountTypeValueId,
        number: '123',
        description: 'Value d 1',
      },
      enabled: true,
      percentageUsed: 20,
    },
    {
      id: 'bb50273a-5200-44d4-80e1-3a150d1b4188',
      name: 'Accounting Values for Marketing',
      createdBy: 'Erica Mustermann',
      refreshPeriod: 'QUARTERLY',
      budgetLimit: {
        amount: 270000,
        currency: 'GBP',
      },
      budgetLeft: {
        amount: 25000,
        currency: 'GBP',
      },
      budgetSpent: {
        amount: 0,
        currency: 'GBP',
      },
      additionalBudgetAmount: {
        amount: 0,
        currency: 'GBP',
      },
      additionalBudgetSpent: {
        amount: 0,
        currency: 'GBP',
      },
      additionalBudgetLeft: {
        amount: 0,
        currency: 'GBP',
      },
      totalBudgetLimit: {
        amount: 270000,
        currency: 'GBP',
      },
      totalBudgetLeft: {
        amount: 25000,
        currency: 'GBP',
      },
      enabled: false,
      percentageUsed: 10,
    },
    {
      id: 'bb50273a-5200-44d4-80e1-3a150d1b4199',
      name: 'Sales budget for Q4',
      createdBy: 'Max Zimmermann',
      refreshPeriod: 'MONTHLY',
      budgetLimit: {
        amount: 700000,
        currency: 'GBP',
      },
      budgetLeft: {
        amount: 655000,
        currency: 'GBP',
      },
      budgetSpent: {
        amount: 45000,
        currency: 'GBP',
      },
      additionalBudgetAmount: {
        amount: 0,
        currency: 'GBP',
      },
      additionalBudgetSpent: {
        amount: 0,
        currency: 'GBP',
      },
      additionalBudgetLeft: {
        amount: 0,
        currency: 'GBP',
      },
      totalBudgetLimit: {
        amount: 700000,
        currency: 'GBP',
      },
      totalBudgetLeft: {
        amount: 655000,
        currency: 'GBP',
      },
      accountValue: {
        id: '1234',
        number: '14',
        description: 'Sales',
      },
      accountType: {
        id: '7eced8a1-daaf-4020-879e-99e9ea66a041' as AccountTypeId,
        prefix: 'K',
        description: 'Project Name',
      },
      enabled: false,
      percentageUsed: 85,
    },
    {
      id: 'bb50273a-5200-44d4-80e1-3a150d1b4144',
      name: 'C-Level management expenses',
      refreshPeriod: 'NONE',
      createdBy: 'Anakin Skywalker',
      budgetLimit: {
        amount: 25000000,
        currency: 'GBP',
      },
      budgetLeft: {
        amount: 15000000,
        currency: 'GBP',
      },
      budgetSpent: {
        amount: 10000000,
        currency: 'GBP',
      },
      additionalBudgetAmount: {
        amount: 2000000,
        currency: 'GBP',
      },
      additionalBudgetSpent: {
        amount: 1000000,
        currency: 'GBP',
      },
      additionalBudgetLeft: {
        amount: 1000000,
        currency: 'GBP',
      },
      totalBudgetLimit: {
        amount: 27000000,
        currency: 'GBP',
      },
      totalBudgetLeft: {
        amount: 16000000,
        currency: 'GBP',
      },
      accountType: {
        id: '7eced8a1-daaf-4020-879e-99e9ea66a041' as AccountTypeId,
        prefix: 'K',
        description: 'Project Name',
      },
      accountValue: {
        id: '7eced8a1-daaf-4020-879e-99e9ea66a041' as AccountTypeValueId,
        number: '23492352',
        description: 'Value description',
      },
      enabled: true,
      percentageUsed: 125,
    },
  ],
}

export const budgetsGetMock = http.get(
  getCustomerMockUrl(generatedApi.customers.getBudgetsList.url),
  () => HttpResponse.json(budgetsMockData),
)

export const makeBudgetsGetMock = (
  budgetsGetMockDataResponse: ModelGetBudgetsListData,
) =>
  http.get(getCustomerMockUrl(generatedApi.customers.getBudgetsList.url), () =>
    HttpResponse.json(budgetsGetMockDataResponse),
  )
