import styled from '@emotion/styled'
import { Flex, type FlexProps } from '@chakra-ui/react'

export const CounterWrapper = styled((props: FlexProps) => <Flex {...props} />)(
  ({ theme: { colors, borders } }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: colors.blue_500,
    padding: '0 2px',
    border: borders.primary,
    borderRadius: '4px',
  }),
)
