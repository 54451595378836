import type { TagProps as BaseTagProps } from '@chakra-ui/react'
import { Tag as BaseTag } from '@chakra-ui/react'
import styled from '@emotion/styled'

export type TagProps = Omit<BaseTagProps, 'variant' | 'size'> & {
  variant?: Extract<BaseTagProps['variant'], 'outline' | 'subtle' | 'solid'>
  type?:
    | 'normal'
    | 'neutral'
    | 'negative'
    | 'positive'
    | 'warning'
    | 'accent'
    | 'naked'
    | 'accentPrimary'
  size?: 'sm' | 'md'
  dataCy?: string
  dataTestId?: string
}

const TagStyled = styled(BaseTag)<TagProps>(
  ({ theme: { colors } }) => ({
    fontWeight: 600,
    lineHeight: '20px',
    color: colors.white,
    whiteSpace: 'break-spaces',
  }),
  ({ size }) => {
    switch (size) {
      case 'sm':
        return {
          fontSize: '12px',
          padding: '0px 8px',
        }
      case 'md': {
        return {
          fontSize: '14px',
          padding: '2px 12px',
        }
      }
    }
  },
  ({ theme: { colors, borders }, type }) => {
    switch (type) {
      case 'normal':
        return {
          background: colors.backgroundC,
          color: colors.contentA,
        }
      case 'negative':
        return {
          background: colors.red_500,
        }
      case 'neutral':
        return {
          background: colors.white,
          border: borders.primary,
          color: colors.contentB,
        }
      case 'warning':
        return {
          background: colors.orange_600,
        }
      case 'positive':
        return {
          background: colors.green_600,
        }
      case 'accent':
        return {
          background: colors.blue_500,
        }
      case 'naked':
        return {
          background: colors.white,
          color: colors.contentA,
          boxShadow: `0px 1px 2px ${colors.tagShadow}`,
        }
      case 'accentPrimary':
        return {
          background: colors.accentPrimary,
          color: colors.white,
        }
    }
  },
)

export const Tag = ({
  type = 'neutral',
  size = 'md',
  dataCy,
  dataTestId,
  ...rest
}: TagProps) => (
  <TagStyled
    type={type}
    size={size}
    data-testid={dataTestId}
    flexShrink={0}
    {...rest}
  />
)
