import type { ReactNode } from 'react'
import React from 'react'

import { ExcludesFalsy } from 'types/utils'

import type { DotSeparatorProps } from './DotSeparator'
import { DotSeparator } from './DotSeparator'

type WithDotsSeparatorWrapperProps = {
  children: ReactNode[] | ReactNode
} & DotSeparatorProps

export const WithDotsSeparatorWrapper = ({
  children,
  ...dotSeparatorProps
}: WithDotsSeparatorWrapperProps) => {
  if (!Array.isArray(children)) return children

  const childrenFiltered = children.filter(ExcludesFalsy)

  return childrenFiltered.map((child, index) => {
    const isLastElement = index === childrenFiltered.length - 1

    return (
      <React.Fragment key={index}>
        {child}
        {!isLastElement && <DotSeparator {...dotSeparatorProps} />}
      </React.Fragment>
    )
  })
}
