import type { MenuDividerProps } from '@chakra-ui/react'
import { Box, HStack, Menu, MenuButton, Portal } from '@chakra-ui/react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import type { MouseEvent } from 'react'

import { translations } from 'locales/translations'
import type { Color } from 'theme/colors'
import { theme } from 'theme'

import { TrashIcon } from 'app/components/icons/components/TrashIcon'
import { UserUnassignIcon } from 'app/components/icons/components/UserUnassignIcon'

import { Divider } from '../Divider'
import { HideOnPrintout } from '../printoutUtils/HideOnPrintout'

import { ActionsButton, ActionItem, MenuListStyled } from './styles'
import type { ActionsMenuProps, MenuListWrapperProps } from './types'

const messages = translations.common.table.actions

export const DeleteActionLabel = ({ label }: { label?: string }) => {
  const { t } = useTranslation()

  return (
    <HStack width="100%" justifyContent="space-between">
      <span>{label || t(messages.delete.label)}</span>
      <TrashIcon />
    </HStack>
  )
}

export const UnassignAllLabel = ({ label }: { label?: string }) => {
  const { t } = useTranslation()

  return (
    <HStack width="100%" justifyContent="space-between">
      <span>{label || t(messages.unassignAll.label)}</span>
      <UserUnassignIcon />
    </HStack>
  )
}

export const ActionDivider = (
  props: Omit<MenuDividerProps, 'color'> & { color?: Color },
) => <Divider my="4px" {...props} />

export { ActionItem }

const stopPropagationClickHandler = (event: MouseEvent<HTMLButtonElement>) =>
  event.stopPropagation()

const MenuListWrapper = ({
  children,
  isWithoutPortal,
}: MenuListWrapperProps) => {
  if (!isWithoutPortal) {
    return <Portal>{children}</Portal>
  }

  return children
}

export const ActionsMenu = ({
  children: menuItems,
  menuButton,
  contentWidth,
  dataTestId,
  buttonVariant = 'secondary',
  menuVariant = 'primary',
  isWithoutPortal,
  isDisabled,
  ...menuProps
}: ActionsMenuProps) => (
  <Box>
    <Menu autoSelect={false} {...menuProps}>
      {menuButton ? (
        <MenuButton onClick={stopPropagationClickHandler} as="div">
          {menuButton}
        </MenuButton>
      ) : (
        <ActionsButton
          aria-label="more"
          as="div"
          variant={buttonVariant}
          onClick={stopPropagationClickHandler}
          isDisabled={isDisabled}
        >
          <MenuButton padding="10px" disabled={isDisabled}>
            <BsThreeDotsVertical size={16} />
          </MenuButton>
        </ActionsButton>
      )}
      <HideOnPrintout>
        <MenuListWrapper isWithoutPortal={isWithoutPortal}>
          <MenuListStyled
            zIndex={theme.zIndexes.header}
            data-testid={dataTestId ?? 'actionsMenu'}
            width={contentWidth}
            variant={menuVariant}
          >
            {menuItems}
          </MenuListStyled>
        </MenuListWrapper>
      </HideOnPrintout>
    </Menu>
  </Box>
)
