import { Box, Stack } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Divider } from 'app/components/Divider'

import { usePageOffsetTop } from '../../usePageOffsetTop'

import { NavWrapper, Nav, NavList, Heading, NavItem } from './styles'

type SideNavOption = {
  icon?: ReactNode
  titleMsg: string
  path: string
  dataCy: string
}

export type SideNavigationStructure = {
  titleMsg: string
  options: SideNavOption[]
}[]

type SideNavigationProps = {
  navigationStructure: SideNavigationStructure
  navigationBottomSection: ReactNode
}

export const SideNavigation = ({
  navigationStructure,
  navigationBottomSection,
}: SideNavigationProps) => {
  const { t } = useTranslation()
  const pageOffsetTop = usePageOffsetTop()

  return (
    <NavWrapper>
      <Nav pageOffsetTop={pageOffsetTop}>
        <Stack
          spacing="16px"
          flexGrow={1}
          overflow="auto"
          divider={<Divider />}
        >
          {navigationStructure.map((section, index) => (
            <section key={section.titleMsg ?? index}>
              {section.titleMsg && <Heading>{t(section.titleMsg)}</Heading>}

              <NavList>
                {section.options.map((navItem) => (
                  <NavItem
                    key={navItem.titleMsg}
                    data-testid={navItem.dataCy}
                    to={navItem.path}
                  >
                    <Box mr="8px">{navItem.icon}</Box>

                    <span>{t(navItem.titleMsg)}</span>
                  </NavItem>
                ))}
              </NavList>
            </section>
          ))}
        </Stack>
        {navigationBottomSection}
      </Nav>
    </NavWrapper>
  )
}
