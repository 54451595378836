import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

import { IS_TEST } from 'consts/envVars'

import { showGenericErrorModal } from 'app/App/globalModals/GenericErrorModal'

export const queryClientOptions = {
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { onError, suppressGlobalOnError } = query.meta ?? {}

      if (!onError && !suppressGlobalOnError) {
        return showGenericErrorModal({ error })
      }

      onError?.(error) // INFO: `onError` callbacks are NOT available on `useQuery`, thus we need to call them manually here
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      const suppressGlobalOnError = mutation.meta?.suppressGlobalOnError // INFO: we need to use `suppressGlobalOnError` as we don't have access to `mutate` onErrors, only `useMutation` onError is available (see below)
      const { onError } = mutation.options

      if (!onError && !suppressGlobalOnError) showGenericErrorModal({ error })
      // INFO: `onError` callbacks are available on `useMutation` / `mutate`, thus we DON'T need to call them manually here
    },
  }),
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
}

export const queryClient = new QueryClient(queryClientOptions)

if (IS_TEST) queryClient.setDefaultOptions({ queries: { retry: false } })
