import type { ReactNode } from 'react'
import type { PopoverProps } from '@chakra-ui/react'

import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'
import { SupportIcon } from 'app/components/icons'

import type { SupportPopoverProps } from './components'
import { SupportPopover } from './components'

type SupportButtonProps = {
  shouldBeWrappedInPortal?: boolean
  placement?: PopoverProps['placement']
} & (
  | {
      triggerRender: (
        state: Parameters<SupportPopoverProps['children']>[0],
      ) => ReactNode
      triggerButtonProps?: never
    }
  | {
      triggerRender?: never
      triggerButtonProps?: ButtonProps
    }
)

export const SupportButton = ({
  shouldBeWrappedInPortal,
  placement,
  triggerRender,
  triggerButtonProps,
}: SupportButtonProps) => (
  <SupportPopover
    shouldBeWrappedInPortal={shouldBeWrappedInPortal}
    placement={placement}
  >
    {(state) =>
      triggerRender ? (
        triggerRender(state)
      ) : (
        <Button
          size="squareMd"
          variant="secondary"
          data-testid="supportPopoverButton"
          {...triggerButtonProps}
        >
          <SupportIcon />
        </Button>
      )
    }
  </SupportPopover>
)
