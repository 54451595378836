import { Link } from 'react-router-dom'

import { API_URL } from 'consts/envVars'
import { PATHS } from 'consts/paths'

import { useCurrentUser, useOciSession } from 'app/containers/common/Auth'
import { LogoWithFallback } from 'app/containers/customers/common/LogoWithFallback'

export const Logo = () => {
  const { isOciCustomer, selectedCompanyId } = useCurrentUser()
  const { ociSessionId } = useOciSession()

  const logoUrl =
    API_URL +
    (isOciCustomer
      ? `customer-oci-session/${ociSessionId}/logo`
      : `customers/${selectedCompanyId}/logo`)

  return (
    <Link to={PATHS.customer.home} data-testid="homeLinkLogo">
      <LogoWithFallback logoUrl={logoUrl} height="24px" />
    </Link>
  )
}
