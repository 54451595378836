import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'

import { colors } from 'theme/colors'
import { translations } from 'locales/translations'

import { Button } from 'app/components/Buttons/Button'
import { StarIcon } from 'app/components/icons/components/StarIcon'
import { MarkerTooltip } from 'app/components/tooltips'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { showArticleListAssignmentsModalFlow } from '../ArticleListAssignmentsModalFlow'

import type { AddArticleToListButtonProps } from './types'

const tooltipMessages = translations.pages.customer.search.addArticleToList

const AddArticleToListButtonWrapper = ({
  children,
  shouldRenderTooltip,
  isAddedToList,
}: {
  children: ReactNode
  shouldRenderTooltip: boolean
  isAddedToList: boolean
}) => {
  const { t } = useTranslation()

  if (!shouldRenderTooltip) return <>{children}</>

  return (
    <MarkerTooltip
      content={t(
        isAddedToList
          ? tooltipMessages.tooltipArticleInList
          : tooltipMessages.tooltipArticleNotInList,
      )}
    >
      {children}
    </MarkerTooltip>
  )
}

const ArticleAddedToListIcon = ({
  isAddedToList,
}: {
  isAddedToList: boolean
}) => <StarIcon fill={isAddedToList ? colors.contentA : 'none'} />

export const AddArticleToListButton = ({
  article,
  vendor,
  size = 'squareMd',
}: AddArticleToListButtonProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const isArticleAddedToList = !!article.isIncludedInArticleList

  return (
    <AddArticleToListButtonWrapper
      shouldRenderTooltip={!isMobile}
      isAddedToList={isArticleAddedToList}
    >
      <Button
        data-testid="addArticleToListButton"
        size={size}
        variant="secondary"
        onClick={() => showArticleListAssignmentsModalFlow({ article, vendor })}
      >
        <ArticleAddedToListIcon isAddedToList={isArticleAddedToList} />
      </Button>
    </AddArticleToListButtonWrapper>
  )
}
