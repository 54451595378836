import styled from '@emotion/styled'
import { Flex } from '@chakra-ui/react'

import { MAX_WIDTH_1310PX_HEADER_BREAKPOINT_MEDIA_QUERY } from './useCustom1310Breakpoint'

export const CustomerNavWrapper = styled(Flex)(
  ({ theme: { dimensions, mediaQuery } }) => ({
    alignItems: 'center',
    columnGap: '32px',
    rowGap: '8px',
    padding: '12px 48px',
    flexWrap: 'wrap',
    alignSelf: 'center',
    width: '100%',
    maxWidth: dimensions.customerContentMaxWidth,

    [mediaQuery.large]: {
      columnGap: '48px',
    },

    [MAX_WIDTH_1310PX_HEADER_BREAKPOINT_MEDIA_QUERY]: {
      columnGap: '8px',
    },
  }),
)

export const HeaderActionsWrapper = styled(Flex)(() => ({
  alignItems: 'center',
  columnGap: '8px',

  [MAX_WIDTH_1310PX_HEADER_BREAKPOINT_MEDIA_QUERY]: {
    columnGap: '2px',
  },
}))
