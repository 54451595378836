import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { Modal as BaseModal, ModalOverlay } from '@chakra-ui/react'

import { SectionHeader } from 'app/components/SectionHeader'
import { Divider } from 'app/components/Divider'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'

import type { ModalProps } from '../types'

import {
  FooterSectionWrapper,
  ModalContentStyled,
  ModalFooterWrapper,
  PanelStyled,
} from './styles'
import { useHideOnPathnameChange } from './useHideOnPathnameChange'
import { CloseButton, FormWrapper } from './components'
import { useModalPropsWithSizeDefaults } from './useModalPropsWithSizeDefaults'

export const Modal = NiceModal.create<ModalProps>(
  ({
    children,
    footer,
    header,
    rightSideRendered,
    subHeader,
    handleClose,
    width,
    maxHeight,
    shouldWrapWithForm,
    onKeyDown,
    contentPadding,
    ...props
  }) => {
    const modal = useModal()

    const {
      isFullScreen,
      isWithXButton,
      isWithoutFooterBorder,
      isWithoutHeaderBorder,
      ...rest
    } = useModalPropsWithSizeDefaults(props)

    useEffect(
      () => () => modal.resolveHide(), // INFO: needed for 'await NiceModal.hide()'. We want to run it only on unmount
      [], // eslint-disable-line react-hooks/exhaustive-deps
    )

    useHideOnPathnameChange(modal) // INFO: hide modals on route/pathname change

    const onCloseComplete = () => {
      if (handleClose) handleClose()

      modal.remove()
    }

    const renderContent = (
      content: ModalProps['footer'] | ModalProps['children'],
    ): React.ReactNode =>
      typeof content === 'function'
        ? content((data?: unknown) => {
            // INFO: `modal.resolve` must be called before `modal.hide`,
            // in order to resolve the promise returned by show
            modal.resolve(data)

            return modal.hide()
          })
        : content

    const sectionHeaderRightSideRendered = isWithXButton ? (
      <CloseButton onCloseComplete={onCloseComplete} />
    ) : (
      rightSideRendered
    )

    return (
      <BaseModal
        isCentered
        size={isFullScreen ? 'full' : undefined}
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={onCloseComplete}
        {...rest}
      >
        <ModalOverlay bg="rgba(165,177,202,0.25)" />

        <FormWrapper
          // INFO: needs to be on top level to not break scroll
          shouldWrapWithForm={shouldWrapWithForm}
          isFullScreen={isFullScreen}
          onKeyDown={onKeyDown}
        >
          <ModalContentStyled
            width={width}
            maxWidth={width}
            maxHeight={maxHeight}
            data-testid={modal.id}
            isFullScreen={isFullScreen}
          >
            <PanelStyled
              contentPadding={contentPadding}
              isFullScreen={isFullScreen}
            >
              {(header || subHeader) && (
                <ColumnWithGap flexGrow={0}>
                  <SectionHeader
                    header={header}
                    rightSideRendered={sectionHeaderRightSideRendered}
                    description={subHeader}
                  />

                  {!isWithoutHeaderBorder && <Divider />}
                </ColumnWithGap>
              )}

              {children && (
                <ColumnWithGap overflow="auto">
                  {renderContent(children)}
                </ColumnWithGap>
              )}

              {footer && (
                <FooterSectionWrapper>
                  {!isWithoutFooterBorder && <Divider />}
                  <ModalFooterWrapper>
                    {renderContent(footer)}
                  </ModalFooterWrapper>
                </FooterSectionWrapper>
              )}
            </PanelStyled>
          </ModalContentStyled>
        </FormWrapper>
      </BaseModal>
    )
  },
)
