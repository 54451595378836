import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'
import type { ModelGetArticleListData } from 'libs/openapiGeneratedApi/Api'

import { ActionItem } from 'app/components/ActionsMenu/styles'
import {
  ActionDivider,
  ActionsMenu,
  DeleteActionLabel,
} from 'app/components/ActionsMenu/ActionsMenu'
import {
  showArticleListDeleteConfirmationModal,
  showCreateUpdateArticlesListModal,
} from 'app/pages/customers/ArticleLists/components'

import { ActionsMenuButton } from './ActionsMenuButton'
import { PagePrintButton } from './PagePrintButton'
import { useBarcodeGenerationOption } from './useBarcodeGenerationOption'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details.menu

export type MenuProps = Pick<
  ModelGetArticleListData,
  'id' | 'name' | 'accessLevel'
>

export const Menu = ({ id, name, accessLevel }: MenuProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    noArticlesOnList,
    onGenerateBarcodesClick,
    generatedBarcodesCounter,
  } = useBarcodeGenerationOption({ articleListId: id })

  const isListOwner = accessLevel === 'ADMIN'

  const onDeleteClick = () =>
    showArticleListDeleteConfirmationModal({
      id,
      name,
      onDeleteSuccess: () => {
        history.push(PATHS.customer.companyAndSettings.articleLists)
      },
    })

  return (
    <ActionsMenu menuButton={<ActionsMenuButton />} isLazy>
      {isListOwner && (
        <ActionItem
          onClick={() => showCreateUpdateArticlesListModal({ id, name })}
        >
          {t(translations.formActions.rename)}
        </ActionItem>
      )}

      <PagePrintButton noArticlesOnList={noArticlesOnList} />

      <ActionItem
        isDisabled={noArticlesOnList}
        onClick={onGenerateBarcodesClick}
      >
        {t(messages.generateBarcodes)}
        {generatedBarcodesCounter}
      </ActionItem>

      {isListOwner && (
        <>
          <ActionDivider />
          <ActionItem onClick={onDeleteClick}>
            <DeleteActionLabel />
          </ActionItem>
        </>
      )}
    </ActionsMenu>
  )
}
