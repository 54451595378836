import { useMutation, useQueryClient } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'
import type { ModelUpdateFastOrderListItemPayload } from 'libs/openapiGeneratedApi/Api'

import type { UseFastOrderHookArgs } from '../types'
import { getFastOrderListQueryKey } from '../List'

export const useUpdateFastOrderListItemMutation = ({
  fastOrderListEnum,
}: UseFastOrderHookArgs) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      fastOrderListItemId,
      ...params
    }: {
      fastOrderListItemId: string
    } & ModelUpdateFastOrderListItemPayload) =>
      generatedApi.customers.updateFastOrderListItem.request({
        fastOrderListEnum,
        fastOrderListItemId,
        data: params,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: getFastOrderListQueryKey({ fastOrderListEnum }),
      }),
  })
}
