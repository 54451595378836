import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { assertNever } from 'types/utils'

import { useGetShowSuccessToast } from 'app/components/Notification/useGetShowSuccessToast'

import type { UseSaveEditArticleModalProps } from './types'

const messages = translations.pages.customer.editArticleDetailsModal

export const useSaveEditArticleModal = ({
  editCustomerArticleNumberFormik,
  isUpdatingCustomerArticleNumber,
  setActiveTab,
  activeTab,
}: UseSaveEditArticleModalProps) => {
  const resetActiveTab = () => setActiveTab(undefined)
  const { t } = useTranslation()
  const { showSuccessToast } = useGetShowSuccessToast()

  const isSaveButtonDisabled = (() => {
    switch (activeTab) {
      case 'buyerArticleNumber':
        return (
          !editCustomerArticleNumberFormik.dirty ||
          isUpdatingCustomerArticleNumber
        )
      case 'externalReferences':
        return false
      default:
        return true
    }
  })()

  const onSaveHandler = async (closeModal: () => void) => {
    switch (activeTab) {
      case 'buyerArticleNumber':
        await editCustomerArticleNumberFormik.submitForm()
        break
      case 'externalReferences':
        closeModal()
        break
      default:
        assertNever(activeTab)
    }
    resetActiveTab()
  }

  return {
    onSaveHandler,
    isSaveButtonDisabled,
  }
}
