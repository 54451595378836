import { ImageWrapper } from './styles'
import type { ImageWithFallbackProps } from './types'
import { MemoizedImage } from './MemoizedImage'

export const ImageWithFallback = ({
  containerWidth,
  containerHeight,
  marginRight,
  onClick,
  ...rest
}: ImageWithFallbackProps) => (
  <ImageWrapper
    width={containerWidth}
    height={containerHeight}
    marginRight={marginRight}
    data-testid="articleImage"
    onClick={onClick}
  >
    <MemoizedImage {...rest} />
  </ImageWrapper>
)
