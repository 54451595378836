import { LIST_VIEW } from 'consts/view'

import { useUserMeQuery } from './useUserMeQuery'

export const useUserMeSettings = () => {
  const { data: { settings } = {} } = useUserMeQuery()

  return {
    ...settings,
    isListViewEnabled:
      !settings?.viewSettings?.searchPage ||
      settings.viewSettings.searchPage === LIST_VIEW.list,
  }
}
