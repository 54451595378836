/*
 * INFO: this is needed to handle the case when user is using old version of the frontend app
 * and we want to force reload, so that index.html is refetched and new FE app files are used (new app version)
 */

import axios from 'axios'
import { minutesToMilliseconds } from 'date-fns'
import { throttle } from 'lodash'
import { useEffect } from 'react'

type VersionJson = {
  version: string
}

export const STORAGE_APP_VERSION_KEY = 's2ng-fe-app-version'

const onVisibilityChange = async () => {
  if (!document.hidden) {
    // app has been focused
    const versionJsonUrl = `${window.location.origin}/version.json`
    const {
      data: { version: serverAppVersion },
    } = await axios.get<VersionJson>(versionJsonUrl)

    const localAppVersion: string = JSON.parse(
      window.localStorage.getItem(STORAGE_APP_VERSION_KEY) || 'null',
    )

    if (serverAppVersion && localAppVersion !== serverAppVersion) {
      // force reload to use the newest frontend app files
      window.localStorage.setItem(
        STORAGE_APP_VERSION_KEY,
        JSON.stringify(serverAppVersion),
      )

      window.location.reload()
    }
  }
}

const onVisibilityChangeDebounced = throttle(
  onVisibilityChange,
  minutesToMilliseconds(5),
)

export const useReloadIfAppVersionChanged = () => {
  useEffect(() => {
    document.addEventListener(
      'visibilitychange',
      onVisibilityChangeDebounced,
      false,
    )

    return () => {
      document.removeEventListener(
        'visibilitychange',
        onVisibilityChangeDebounced,
      )
    }
  }, [])
}
