import type {
  CustomerPermissionGroup,
  MODEL__AvailableFeatures,
  MODEL__CompanyUser,
  MODEL__CustomerPrivilege,
} from 'app/models/companyUser'

export type GetCompanyUserMeMockDataArgs = {
  companyUserId?: string
  availableFeatures?: MODEL__AvailableFeatures[]
  editOptions?: MODEL__CompanyUser['editOptions']
} & (
  | {
      isCustomer: true
      permissionGroup?: CustomerPermissionGroup
      assignedPrivileges?: MODEL__CustomerPrivilege[]
      additionalInformation?: string
    }
  | {
      isCustomer: false
      permissionGroup?: never
      assignedPrivileges?: never
      additionalInformation?: never
    }
)

export const getCompanyUserMeMockData = ({
  isCustomer,
  permissionGroup,
  companyUserId,
  assignedPrivileges,
  availableFeatures,
  editOptions,
  additionalInformation,
}: GetCompanyUserMeMockDataArgs): MODEL__CompanyUser => ({
  companyUserId: companyUserId ?? 'b2aa3d67-48c9-432e-8c44-869f1d20173e',
  editOptions: editOptions ?? {
    email: false,
  },
  profile: {
    person: {
      title: 'MS',
      firstName: 'Jane',
      lastName: 'Doe',
      phoneNumber: '+49 25895616',
    },
    email: 'Jane@email.com',
    ...(isCustomer && {
      permissionGroup: permissionGroup ?? 'ADMIN',
      assignedPrivileges: assignedPrivileges ?? [],
      additionalInformation: additionalInformation ?? '',
    }),
  },

  invitation: {
    createdAt: '2021-11-25T11:10:57.849Z',
    status: 'ACCEPTED',
    acceptedAt: '2021-11-25T11:10:57.849Z',
    expiresAt: '2021-11-25T11:10:57.849Z',
  },
  lastActiveAt: '2022-01-12T11:10:57.849Z',
  availableFeatures:
    availableFeatures ??
    (isCustomer
      ? ['OCI_INTEGRATION', 'SET_ORDER_NUMBER', 'VENDING_MACHINE_INTEGRATION']
      : ['OCI_CATALOGS']),
  ...(isCustomer && {
    groupName: 'Accountiers',
    groupId: '8484848484',
    assignedFeatures: {
      accountingValues: 132,
      generalLedgers: 9,
      approvalFlows: 0,
      articleLists: 1,
      deliveryAddresses: 2,
      catalogViews: 1,
      priceRestrictions: 1,
      cartRestrictions: 1,
    },
  }),
})
