import { http, HttpResponse } from 'msw'
import { omit } from 'lodash'

import { generatedApi } from 'apiServices'
import type {
  ModelCustomerArticleSearchData,
  ModelCustomerArticleSearchParams,
  ModelCustomerArticleSearchPayload,
} from 'libs/openapiGeneratedApi/Api'

import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'
import { getVendorMockUrl } from 'app/containers/vendors/utils'

import {
  makeArticlesMockData,
  searchValuesWithVendorsList,
  searchValuesWithManufacturersList,
  searchValuesNoFilters,
  searchData,
  articleFilterValues,
  searchValuesWithPricesList,
} from './mockData'

const marketplaceArticlesSearchRequestUrl =
  generatedApi.customers.customerArticleSearch.url

// TODO: add mocks per filter https://simplesystem.atlassian.net/browse/S2NG-3528

export const makeArticlesSearchMock = ({
  bodySpy,
  searchParamsSpy,
  articlesMockData,
}: {
  bodySpy?: jest.Mock
  searchParamsSpy?: jest.Mock
  articlesMockData?: ModelCustomerArticleSearchData
} = {}) =>
  http.post(
    getCustomerMockUrl(marketplaceArticlesSearchRequestUrl),
    async ({ request }) => {
      const url = new URL(request.url)
      const body = (await request.json()) as ModelCustomerArticleSearchPayload &
        ModelCustomerArticleSearchParams

      if (bodySpy) {
        bodySpy(body)
      }

      if (searchParamsSpy) {
        searchParamsSpy(Object.fromEntries(url.searchParams))
      }

      return HttpResponse.json(
        articlesMockData ??
          makeArticlesMockData(body.term, Number(url.searchParams.get('page'))),
      )
    },
  )

export const articlesSearchMock = makeArticlesSearchMock()

export const marketplaceVendorsFilterPostMock = http.post(
  getCustomerMockUrl(marketplaceArticlesSearchRequestUrl),
  async () =>
    HttpResponse.json({ ...searchData, ...searchValuesWithVendorsList }),
)

export const makeCustomersArticleSearchWithPricesMock = (spyFn?: jest.Mock) =>
  http.post(
    getCustomerMockUrl(generatedApi.customers.customerArticleSearch.url),
    async ({ request }) => {
      if (spyFn) {
        spyFn(await request.json())
      }

      return HttpResponse.json({ ...searchData, ...searchValuesWithPricesList })
    },
  )

export const marketplaceManufacturersFilterPostMockWithSpy = (
  spyFn?: jest.Mock,
) =>
  http.post(
    getCustomerMockUrl(marketplaceArticlesSearchRequestUrl),
    async ({ request }) => {
      if (spyFn) spyFn(await request.json())

      return HttpResponse.json(
        { ...searchData, ...searchValuesWithManufacturersList },
        { status: 200 },
      )
    },
  )

export const marketplaceArticlesFilterValuesPostMock = http.post(
  getCustomerMockUrl(marketplaceArticlesSearchRequestUrl),
  async ({ request }) => {
    const url = new URL(request.url)
    const body = (await request.json()) as { term?: string }

    const articlesMockData = makeArticlesMockData(
      body.term,
      Number(url.searchParams.get('page')),
    )

    return HttpResponse.json({ ...articlesMockData, ...searchValuesNoFilters })
  },
)

export const ociCustomerArticlesSearchMock = http.post(
  getOciCustomerMockUrl(marketplaceArticlesSearchRequestUrl),
  async ({ request }) => {
    const url = new URL(request.url)
    const body = (await request.json()) as { term?: string }

    return HttpResponse.json(
      makeArticlesMockData(body.term, Number(url.searchParams.get('page'))),
    )
  },
)

export const makeVendorArticlesPreviewMockWithSpy = ({
  bodySpy,
  searchParamsSpy,
  articlesMockData: mockDataFromProps,
}: {
  bodySpy?: jest.Mock
  searchParamsSpy?: jest.Mock
  articlesMockData?: ModelCustomerArticleSearchData
} = {}) =>
  http.post(
    getVendorMockUrl(generatedApi.vendors.vendorPreviewArticlesSearch.url),
    async ({ request }) => {
      const url = new URL(request.url)
      const body = (await request.json()) as { term?: string }

      if (bodySpy) bodySpy(body)

      if (searchParamsSpy) {
        searchParamsSpy(Object.fromEntries(url.searchParams))
      }

      const articlesMockData =
        mockDataFromProps ??
        makeArticlesMockData(body.term, Number(url.searchParams.get('page')))

      return HttpResponse.json({
        ...omit(articleFilterValues, ['items', 'metadata']),
        ...articlesMockData,
      })
    },
  )

export const vendorArticlesPreviewMock = makeVendorArticlesPreviewMockWithSpy()
