import { useDispatch, useSelector } from 'react-redux'
import { HStack, Icon, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'

import { useTheme } from 'theme'
import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import { useCurrentUser } from 'app/containers/common/Auth'
import { Typography, textStyles } from 'app/components/Typography'
import { smallButtonDimensionStyles } from 'app/components/Buttons/Button'
import { InfoIcon } from 'app/components/icons'
import { resetWithTokens } from 'app/containers/common/Auth/authSlice'
import { tokenEmailSelector } from 'app/containers/common/Auth/selectors'
import { clearDeliveryAddressStore } from 'app/containers/customers/DeliveryAddresses/deliveryAddressesSlice'
import { useCurrentCompany } from 'app/containers/common/Me/User/hooks'
import { useCurrentCompanyUserFullName } from 'app/containers/common/Me/CompanyUser'

const ImpersonationBarWrapper = styled(Flex)(
  ({ theme: { colors, dimensions } }) => ({
    padding: '8px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.contentA,
    height: dimensions.impersonationBarHeight,
  }),
)

const StopAccessButton = styled(Button)(({ theme: { colors } }) => ({
  ...textStyles.inputMediumButtonSb,
  ...smallButtonDimensionStyles,
  borderRadius: '6px',
  margin: '4px 0',
  color: colors.contentD,
  background: 'rgba(193, 212, 241, 0.2)',
  ':focus': {
    boxShadow: 'none',
  },
  ':hover:not([disabled]):not(:active)': {
    background: 'rgba(193, 212, 241, 0.15)',
  },
  ':active': {
    background: 'rgba(193, 212, 241, 0.1)',
  },
}))

export const ImpersonationBar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const backofficeUserEmail = useSelector(tokenEmailSelector)
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { isUserImpersonated, userRole } = useCurrentUser()
  const { name: companyName } = useCurrentCompany() ?? {}
  const fullName = useCurrentCompanyUserFullName()

  if (!isUserImpersonated) return null

  return (
    <ImpersonationBarWrapper>
      <HStack>
        <Icon color={colors.contentD} as={InfoIcon} />
        <Typography textStyle="textSmall" color="contentD">
          {t(translations.impersonationBar.title, {
            fullName,
            companyName,
            backofficeUserEmail,
          })}
        </Typography>
      </HStack>

      <StopAccessButton
        onClick={() => {
          dispatch(resetWithTokens())
          dispatch(clearDeliveryAddressStore())

          if (userRole === 'customer') {
            history.push(PATHS.backoffice.customers)
          }
        }}
      >
        {t(translations.impersonationBar.stopBtnLabel)}
      </StopAccessButton>
    </ImpersonationBarWrapper>
  )
}
