import { Flex } from '@chakra-ui/react'

import type { BareInputProps } from 'app/components/inputs/Input'
import { BareInput } from 'app/components/inputs/Input'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import type { SearchAndPaginationHookProps } from 'app/hooks/useSearchAndPagination'
import { useSearchAndPagination } from 'app/hooks/useSearchAndPagination'
import { SearchIcon, XIcon } from 'app/components/icons'

export const BareSearchInput = ({
  shouldNotResizeOnMobile,
  ...rest
}: BareInputProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const size: BareInputProps['size'] =
    isMobile && !shouldNotResizeOnMobile ? 'sm' : 'md'

  return (
    <BareInput
      aria-label="Search input"
      size={size}
      leftElement={<SearchIcon />}
      {...rest}
    />
  )
}

type SearchInputProps = BareInputProps &
  SearchAndPaginationHookProps & {
    isClearable?: boolean
  }

export const SearchInput = ({
  onChange,
  searchQueryParamName,
  triggerThreshold,
  delay,
  disableReplaceIn,
  shouldPreservePage,
  isClearable,
  ...rest
}: SearchInputProps) => {
  const { inputValue, setInputValue, onKeyDownToSupportSearchOnEnter } =
    useSearchAndPagination({
      searchQueryParamName,
      triggerThreshold,
      delay,
      disableReplaceIn,
      shouldPreservePage,
    })

  const handleClearInput = () => {
    setInputValue('')
  }

  const clearButtonRendered = (
    <Flex
      alignContent="center"
      color="contentB"
      transition="color .2s"
      cursor="pointer"
      _hover={{
        color: 'contentA',
      }}
    >
      <XIcon onClick={handleClearInput} />
    </Flex>
  )

  const shouldShowClearButton = isClearable && !!inputValue

  return (
    <BareSearchInput
      value={inputValue}
      onChange={(e) => {
        if (onChange) onChange(e)
        setInputValue(e.target.value)
      }}
      onKeyDown={onKeyDownToSupportSearchOnEnter}
      rightElement={shouldShowClearButton ? clearButtonRendered : undefined}
      {...rest}
    />
  )
}
