import type { ReactNode } from 'react'
import React from 'react'
import { Flex } from '@chakra-ui/react'

import { colors } from 'theme/colors'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { WithDotsSeparatorWrapper as BaseWithDotsSeparatorWrapper } from 'app/components/DotSeparator/WithDotsSeparatorWrapper'

type ArticleContentDescriptionLineWrapperProps = { children: ReactNode[] }

const WithDotsSeparatorWrapper = (
  props: ArticleContentDescriptionLineWrapperProps,
) => (
  <BaseWithDotsSeparatorWrapper
    color={colors.contentOff}
    margin="0"
    {...props}
  />
)

export const ArticleContentDescriptionLineWrapper = ({
  children,
}: ArticleContentDescriptionLineWrapperProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const Wrapper = isMobile ? React.Fragment : WithDotsSeparatorWrapper

  return (
    <Flex gap="4px" direction={isMobile ? 'column' : 'row'}>
      <Wrapper>{children}</Wrapper>
    </Flex>
  )
}
