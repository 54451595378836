import { useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { useCurrentUser } from 'app/containers/common/Auth'
import {
  useCheckCustomerPermission,
  useCompanyUserMeQuery,
} from 'app/containers/common/Me/CompanyUser'
import {
  useCurrentCompany,
  useUserMeQuery,
} from 'app/containers/common/Me/User'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

// INFO: supporting only customerUser/ociCustomer and possibly vendorUser for now (no backoffice user)
export const UserGuidingHtmlTag = () => {
  const [wasUserIdentified, setWasUserIdentified] = useState(false)
  const [isSdkInitialized, setIsSdkInitialized] = useState(false)
  const { isAdmin: isAdminUser, isShopper: isShopperUser } =
    useCheckCustomerPermission()
  const { isMobile } = useBreakpointScreenSize()
  const { isOciCustomer } = useCurrentUser()
  const { data: { username } = {} } = useUserMeQuery({ enabled: true })
  const { data: { companyUserId, profile } = {} } = useCompanyUserMeQuery() // won't be present for OCI customer
  const currentCompany = useCurrentCompany()

  // INFO: about these 2 useEffects:
  // we need to wait for 2 things: SDK needs to be initialized and user data needs to be present

  useEffectOnce(() => {
    // INFO: we have 2 cases: either SDK is loaded before component mount or after

    if (window.userGuiding && window.userGuiding.identify) {
      setIsSdkInitialized(true)
    } else {
      window.onUserGuidingLoad = () => setIsSdkInitialized(true)
    }
  })

  useEffect(() => {
    const companyUserMeDataPresent = isOciCustomer || companyUserId

    if (
      isMobile ||
      wasUserIdentified ||
      !isSdkInitialized ||
      !currentCompany ||
      !companyUserMeDataPresent
    ) {
      return
    }

    const uniqueUserId = isOciCustomer
      ? `${username}_${currentCompany.id}`
      : companyUserId

    window.userGuiding.identify(uniqueUserId, {
      email: profile?.email,
      username,
      companyName: currentCompany.name,
      ...(isAdminUser && { isAdminUser }),
      ...(isShopperUser && { isShopperUser }),
      ...(isOciCustomer && { isOciCustomer }),
    })

    setWasUserIdentified(true)
  }, [
    wasUserIdentified,
    isSdkInitialized,
    companyUserId,
    profile?.email,
    username,
    currentCompany,
    isAdminUser,
    isShopperUser,
    isOciCustomer,
    isMobile,
  ])

  if (isMobile || !wasUserIdentified) return null // INFO: allow for below tags render (aka "guides to show") only after user identification

  return (
    <div id="userGuidingInfos" style={{ display: 'none' }}>
      {isAdminUser && <span id="isAdminUser" />}
      {isShopperUser && <span id="isShopperUser" />}
      {isOciCustomer && <span id="isOciCustomer" />}
    </div>
  )
}
