import {
  InputLeftElement,
  Input as BareInput,
  InputRightElement,
  forwardRef,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors } from 'theme/colors'

import {
  makeSizeDependentStyles,
  makeCommonInputStyles,
  disabledStateStyles,
  readOnlyStateStyles,
} from '../commonStyles'

import type {
  BareInputStyledProps,
  RightLeftElementStylesProps,
  RightLeftElementProps,
} from './types'

const rightLeftElementCommonStyles = ({
  size,
  isDisabled,
  isError,
}: RightLeftElementStylesProps) =>
  css({
    width: 'unset',
    height: size === 'sm' ? '36px' : '48px',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    margin: 'auto 12px',
    padding: 0,
    color:
      (isDisabled && colors.contentOff) ||
      (isError && colors.red_500) ||
      colors.contentA,
  })

export const InputLeftElementStyled = styled(
  ({
    size,
    isDisabled,
    isError,
    leftElementCustomStyles,
    ...props
  }: RightLeftElementProps) => <InputLeftElement zIndex={1} {...props} />,
)(
  ({ size, isDisabled, isError }) =>
    rightLeftElementCommonStyles({
      size,
      isDisabled,
      isError,
    }),
  ({ leftElementCustomStyles }) => leftElementCustomStyles,
)

export const InputRightElementStyled = styled(
  ({
    size,
    isDisabled,
    isError,
    rightElementCustomStyles,
    ...props
  }: RightLeftElementProps) => <InputRightElement {...props} />,
)(
  ({ size, isDisabled, isError }) => ({
    ...rightLeftElementCommonStyles({ size, isDisabled, isError }),
    fontWeight: 600,
  }),
  ({ rightElementCustomStyles }) => rightElementCustomStyles,
)

// needed for proper types inside styled()(...)
const BareInputStyledComponent = forwardRef<BareInputStyledProps, 'input'>(
  ({ hasLeftElement, hasRightElement, isError, ...props }, ref) => (
    <BareInput
      padding="8px 16px"
      {...(hasLeftElement && { paddingLeft: '44px' })}
      {...(hasRightElement && { paddingRight: '44px' })}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      {...props}
      ref={ref}
    />
  ),
)

export const BareInputStyled = styled(
  BareInputStyledComponent,
)<BareInputStyledProps>(({ isError, size }) => ({
  ...makeCommonInputStyles(isError),
  ...readOnlyStateStyles,
  ...disabledStateStyles,
  ...makeSizeDependentStyles(size),
}))
