import { http, HttpResponse } from 'msw'

import type { AccountTypeId } from '../models'
import { getCustomerMockUrl } from '../../utils'

import type { API__AssignedAccountTypesResponse } from './hooks'
import { url } from './hooks'

export const assignedAccountTypesMockData: API__AssignedAccountTypesResponse = {
  accountTypes: [
    {
      id: 'bb50273a-5200-44d4-80e1-3a150d1b4149' as AccountTypeId,
      prefix: 'KHYFRFRFRREDED',
      description: 'Marketing',
    },
    {
      id: 'cb50273a-5200-44d4-80e1-3a150d1b414c' as AccountTypeId,
      prefix: 'HK',
      description: 'Expenses',
    },
    {
      id: 'cb50273a-5200-44d4-80e1-3a150d1b414d' as AccountTypeId,
      prefix: 'DDY',
      description: 'Finaces',
    },
    {
      id: 'cb50273a-5200-44d4-80e1-3a150d1b414e' as AccountTypeId,
      prefix: 'AALF',
      description: 'Marketing',
    },
  ],
}

export const singleAssignedAccountTypeMockData: API__AssignedAccountTypesResponse =
  {
    accountTypes: [
      {
        id: 'asd0273a-5200-44d4-80e1-1a150d1bd1fd' as AccountTypeId,
        prefix: 'QWERTY',
        description: 'Office products',
      },
    ],
  }

export const emptyAssignedAccountTypesMockData: API__AssignedAccountTypesResponse =
  {
    accountTypes: [],
  }

export const assignedAccountTypesGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json(assignedAccountTypesMockData),
)

export const singleAssignedAccountTypeGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json(singleAssignedAccountTypeMockData),
)

export const emptyAssignedAccountTypesGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json(emptyAssignedAccountTypesMockData),
)
