import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import type { BoxProps } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { SectionHeader } from '../SectionHeader'

import { EmptyCaseContainer } from './styles'

const emptyCaseTranslations = translations.emptyList

export type EmptyCaseWrapperProps = {
  children?: ReactNode
  icon?: ReactNode
  message?: ReactNode
  subHeader?: ReactNode
  explanation?: ReactNode
  action?: ReactNode
  data?: unknown[]
  isLoading?: boolean
  marginTop?: BoxProps['marginTop']
  shouldHideBorder?: boolean
}

export const StaticEmptyCaseWrapper = ({
  icon,
  message,
  subHeader,
  explanation,
  action,
  marginTop,
  shouldHideBorder,
}: Omit<EmptyCaseWrapperProps, 'children' | 'isFetching' | 'data'>) => {
  const { t } = useTranslation()

  return (
    <EmptyCaseContainer
      data-testid="emptyCaseWrapper"
      marginTop={marginTop}
      shouldHideBorder={shouldHideBorder}
    >
      {icon}

      <SectionHeader
        align="center"
        header={message ?? t(emptyCaseTranslations.default)}
        subHeader={subHeader}
        description={explanation}
        maxWidth="1080px"
        whiteSpace="pre-wrap"
      />

      {action}
    </EmptyCaseContainer>
  )
}

export const EmptyCaseWrapper = ({
  children,
  isLoading,
  data,
  ...rest
}: EmptyCaseWrapperProps) => {
  const isEmpty = !isLoading && data?.length === 0

  return <>{isEmpty ? <StaticEmptyCaseWrapper {...rest} /> : children}</>
}
