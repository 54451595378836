import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { ASSIGNED_ACCOUNT_TYPES_QUERY_KEY } from '../AccountTypes/AssignedToCustomer'
import { ASSIGNED_ACCOUNT_TYPE_VALUES_QUERY_KEY } from '../AccountTypes/Values/AssignedToCustomer'
import { ASSIGNED_GENERAL_LEDGERS_QUERY_KEY } from '../GeneralLedgers/AssignedToCustomer'

export const useAccountingAbsentOnError = () => {
  const queryClient = useQueryClient()

  return (error: unknown) => {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      queryClient.invalidateQueries({
        queryKey: [ASSIGNED_ACCOUNT_TYPES_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [ASSIGNED_ACCOUNT_TYPE_VALUES_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [ASSIGNED_GENERAL_LEDGERS_QUERY_KEY],
      })
    }
  }
}
