import { useQuery } from '@tanstack/react-query'

import { COMPANY_COUNTRY_CONFIG } from 'consts/envVars'
import type { CountryCode } from 'consts/countries'
import { includes } from 'types/utils'

import { getCurrencySymbol } from 'app/utils/formatMoney'

import { useBaseProfileQueryOptions, useProfileQuery } from './query'

type RegistrationAvailableCountryConfig = keyof typeof COMPANY_COUNTRY_CONFIG

const availableCountryConfigs = Object.keys(
  COMPANY_COUNTRY_CONFIG,
) as RegistrationAvailableCountryConfig[]

export const useCompanyCountryConfig = (countryOfOperation?: CountryCode) => {
  const profileQuery = useProfileQuery()

  const companyCountry =
    countryOfOperation ??
    profileQuery.data?.businessInfo.countryOfOperation ??
    ''

  const companyCountryConfig = includes(availableCountryConfigs, companyCountry)
    ? COMPANY_COUNTRY_CONFIG[companyCountry]
    : COMPANY_COUNTRY_CONFIG.OTHER

  return companyCountryConfig
}

export const useCompanyCurrencyFromProfileQuery = () => {
  const baseProfileQueryOptions = useBaseProfileQueryOptions()

  const currency = useQuery({
    ...baseProfileQueryOptions,
    select: (data) => data?.businessInfo.currency,
  }).data

  return currency
}

export function throwIfCompanyCurrencyAbsent(
  companyCurrency: string | undefined,
): asserts companyCurrency is string {
  if (!companyCurrency) throw new Error('companyCurrency is missing!')
}

export const useCompanyCurrencySymbolFromProfileQuery = () => {
  const companyCurrency = useCompanyCurrencyFromProfileQuery()

  return getCurrencySymbol(companyCurrency)
}
