import { http, HttpResponse, delay } from 'msw'

import { USER_ROLE_PATHS } from 'apiServices/requestInterceptors/addBaseUrl'

import { joinApiPaths } from 'app/utils/joinApiPaths'

import { url } from '../api'

import {
  createVendorMockData,
  mockBackofficeData,
  mockCustomerCompanyTypeData,
  mockOciCustomerCompanyTypeData,
} from './data'

export const meVendorCompanyTypeGetMock = http.get(joinApiPaths(url), () =>
  HttpResponse.json(createVendorMockData()),
)

export const meVendorCompanyTypeGetMockWithGermanLocale = http.get(
  joinApiPaths(url),
  () => HttpResponse.json(createVendorMockData({ locale: 'de' })),
)

export const meCustomerCompanyTypeGetMock = http.get(joinApiPaths(url), () =>
  HttpResponse.json(mockCustomerCompanyTypeData),
)

export const meCustomerCompanyTypeWithForcedDelayGetMock = http.get(
  joinApiPaths(url),
  async () => {
    await delay(200)

    return HttpResponse.json(mockCustomerCompanyTypeData)
  },
)

export const meOciCustomerGetMock = http.get(
  joinApiPaths(USER_ROLE_PATHS.ociCustomer),
  () => HttpResponse.json(mockOciCustomerCompanyTypeData),
)

export const meBackofficeGetMock = http.get(joinApiPaths(url), () =>
  HttpResponse.json(mockBackofficeData),
)

export const meSettingsPatchMock = http.patch(
  joinApiPaths(url),
  () =>
    new HttpResponse(null, {
      status: 204,
    }),
)
