import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import type { AvailableLanguages } from 'locales/types'
import { queryClient } from 'queryClient/queryClient'
import { includes } from 'types/utils'
import { AVAILABLE_LANGUAGES_LIST } from 'locales/consts'

import { useUpdateUserMeSettingsMutation } from 'app/containers/common/Me/User/hooks/useUpdateUserMeSettingsMutation'
import { useUserMeSettings } from 'app/containers/common/Me/User/hooks/useUserMeSettings'
import { useIsAuthorized } from 'app/containers/common/Auth/hooks/useIsAuthorized'
import { useOciSession } from 'app/containers/common/Auth/hooks/useOciSession'
import {
  languageSelector,
  setUnauthorizedUserLang,
} from 'app/containers/common/UnauthorizedUserLanguage/languageSlice'

import { LOCALES } from './consts'

export const useLocale = () => {
  const { i18n } = useTranslation()
  const { locale: serverLocale } = useUserMeSettings()
  const { mutate: updateRemoteLocale } = useUpdateUserMeSettingsMutation()
  const { isAuthorized } = useIsAuthorized()
  const { isOciSessionFlow } = useOciSession()
  const dispatch = useDispatch()
  const browserFallbackLocale = i18n.language as AvailableLanguages

  const unauthorizedUserLanguage = useSelector(languageSelector)

  // INFO: we can receive some default serverLocale which we do not support
  const parsedServerLocale = includes(AVAILABLE_LANGUAGES_LIST, serverLocale)
    ? serverLocale
    : undefined

  const currentLocale =
    parsedServerLocale ?? unauthorizedUserLanguage ?? browserFallbackLocale

  const setLocale = useCallback(
    (newLocale: AvailableLanguages) => {
      if (isAuthorized || isOciSessionFlow) {
        updateRemoteLocale({ locale: newLocale })
      } else {
        i18n.changeLanguage(newLocale)
        dispatch(setUnauthorizedUserLang(newLocale)) //save locale in local storage
      }
    },
    [isAuthorized, updateRemoteLocale, i18n, dispatch, isOciSessionFlow],
  )

  useEffect(() => {
    if (currentLocale && currentLocale !== i18n.language) {
      i18n.changeLanguage(currentLocale)

      queryClient.invalidateQueries()
    }
    // INFO: even putting the i18n.changeLanguage in the list doesn't satisfy linter...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale, i18n.language])

  return {
    currentLocale,
    serverLocale: parsedServerLocale,
    browserFallbackLocale,
    setLocale,
    LOCALES,
  }
}
