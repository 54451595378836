import styled from '@emotion/styled'
import { PopoverBody, PopoverContent, Flex } from '@chakra-ui/react'

export const StyledPopoverContent = styled(PopoverContent)(
  ({ theme: { shadows } }) => ({
    boxShadow: shadows.primary,
    marginBottom: '16px',

    '&:focus': {
      boxShadow: shadows.primary,
    },
  }),
)

export const StyledPopoverBody = styled(PopoverBody)(
  () =>
    ({
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    }) as const,
)

export const PopoverOption = styled(Flex)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: '16px 12px',
  borderRadius: '6px',
  ':hover': {
    backgroundColor: theme.colors.backgroundC,
    cursor: 'pointer',
  },
}))
