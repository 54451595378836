import { useQuery } from '@tanstack/react-query'
import { generatePath } from 'react-router-dom'
import axios from 'axios'

import { api } from 'apiServices'
import { throwIfAbsent } from 'types/utils'

import { showGenericErrorModal } from 'app/App/globalModals'

import type { AccountTypeId } from '../../models'
import { assignedAccountTypesScope } from '../../consts'

import type {
  API_AssignedAccountTypeValuesParams,
  API__AssignedAccountTypeValuesResponse,
} from './types'

export const url = `${assignedAccountTypesScope}/:accountTypeId/values` as const

const getAssignedAccountTypeValuesRequest = (
  accountTypeId: AccountTypeId | undefined,
) => {
  throwIfAbsent(accountTypeId)

  return api.get<unknown, API__AssignedAccountTypeValuesResponse>(
    generatePath(url, { accountTypeId }),
  )
}

export const ASSIGNED_ACCOUNT_TYPE_VALUES_QUERY_KEY =
  'assignedAccountTypeValues'

const ACCOUNT_TYPE_DOES_NOT_EXIST = 404

export const useAssignedAccountTypeValuesQuery = ({
  accountTypeId,
  cartItemId,
  on404Error,
}: API_AssignedAccountTypeValuesParams) =>
  useQuery({
    queryKey: [
      ASSIGNED_ACCOUNT_TYPE_VALUES_QUERY_KEY,
      accountTypeId,
      // INFO: We need a more specific key with the cartItemId for the error handling, even though it's not used to perform the request itself- we want to only register the onError handler bounded to accountTypeId and cartItemId to trigger the error handling only when needed
      cartItemId,
    ],
    queryFn: () => getAssignedAccountTypeValuesRequest(accountTypeId),
    staleTime: 1000 * 3,
    enabled: !!accountTypeId,
    refetchOnWindowFocus: false,
    retry: false,
    meta: {
      onError: (error) => {
        if (
          on404Error &&
          axios.isAxiosError(error) &&
          error.response?.status === ACCOUNT_TYPE_DOES_NOT_EXIST
        ) {
          on404Error(error)
        } else {
          showGenericErrorModal({ error })
        }
      },
    },
  })
