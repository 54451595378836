import { useMediaQuery } from '@chakra-ui/react'

import { bareMediaQuery } from 'theme/mediaQueries'

import { useDetectPrintMode } from 'app/utils/print/useDetectPrintMode'

export const useBreakpointScreenSize = () => {
  const [isMobile] = useMediaQuery(bareMediaQuery.small, { ssr: false })
  const [isTablet] = useMediaQuery(bareMediaQuery.medium, { ssr: false })
  const { isPrintMode } = useDetectPrintMode()

  return { isMobile: isMobile && !isPrintMode, isTablet }
}
