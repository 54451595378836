import { Flex } from '@chakra-ui/react'

import { colors } from 'theme/colors'

import { Typography } from 'app/components/Typography/Typography'
import { DotSeparator } from 'app/components/DotSeparator/DotSeparator'
import { ArticleLinkWrapper } from 'app/pages/common/marketplaceAndSearch/components/ArticleLinkWrapper'
import { SoldByText } from 'app/pages/common/marketplaceAndSearch/components/ArticleTexts'

import type { GenericArticleContentProps } from '../types'

type GenericArticleContentHeaderProps = Pick<
  GenericArticleContentProps,
  'isWithoutLinks' | 'articleNameNoOffLines'
> & {
  articleData: Pick<
    GenericArticleContentProps['articleData'],
    'articleId' | 'vendorName' | 'manufacturerName' | 'name'
  >
}

export const GenericArticleContentHeader = ({
  isWithoutLinks,
  articleData,
  articleNameNoOffLines,
}: GenericArticleContentHeaderProps) => {
  const { articleId, vendorName, manufacturerName, name } = articleData

  return (
    <>
      <Flex gap="4px">
        {manufacturerName && (
          <>
            <Typography textStyle="textSmallSb" color="contentB">
              {manufacturerName}
            </Typography>

            <DotSeparator color={colors.contentOff} />
          </>
        )}

        <Typography textStyle="textSmallSb" color="contentB">
          <SoldByText vendorName={vendorName} />
        </Typography>
      </Flex>

      <ArticleLinkWrapper articleId={isWithoutLinks ? undefined : articleId}>
        <Typography textStyle="textNormalSb" noOfLines={articleNameNoOffLines}>
          {name}
        </Typography>
      </ArticleLinkWrapper>
    </>
  )
}
