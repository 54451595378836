import { generatePath } from 'react-router-dom'
import { http, HttpResponse, delay } from 'msw'

import { getDuration } from 'utils/testing/getDuration'

import { getCustomerMockUrl, getOciCustomerMockUrl } from '../../../utils'
import type { AccountTypeId } from '../../models'
import type { AccountTypeValueId } from '../models'

import { url } from './hooks'
import type { API__AssignedAccountTypeValuesResponse } from './types'

export const assignedAccountTypeValuesMockData: API__AssignedAccountTypeValuesResponse =
  {
    accountTypeValues: [
      {
        id: 'bb50273a-5200-44d4-80e1-3a150d1bfwef' as AccountTypeValueId,
        number: '123',
        description: 'Value d 1',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-3a15ad1b414c' as AccountTypeValueId,
        number: '239',
        description: 'Value description description 13',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-1a150d1b414d' as AccountTypeValueId,
        number: '345',
        description: 'Value description 3',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-3a950d1b414e' as AccountTypeValueId,
        number: '456',
        description:
          'Value description description description description4 lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
      },
    ],
  }

export const emptyAssignedAccountTypeValuesGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json({ accountTypeValues: [] }),
)

export const assignedAccountTypeValuesGetMock = http.get(
  getCustomerMockUrl(url),
  async () => {
    await delay(getDuration(1000))

    return HttpResponse.json(assignedAccountTypeValuesMockData)
  },
)

export const assignedAccountTypeValuesOciGetMock = http.get(
  getOciCustomerMockUrl(url),
  async () => {
    await delay(getDuration(1000))

    return HttpResponse.json(assignedAccountTypeValuesMockData)
  },
)

export const getAssignedAccountTypeValues404ErrorMock = http.get(
  getCustomerMockUrl(url),
  () => new HttpResponse(null, { status: 404 }),
)

export const getAssignedAccountTypeValuesMockWithSpy = (
  spy?: jest.Mock,
  assignedValues?: API__AssignedAccountTypeValuesResponse,
  accountTypeIdForMockUrl?: AccountTypeId,
) => {
  // INFO: there are tests that fire 2 GET values requests (for different accountTypeId) and need 2 different responses,
  // so we use generatePath to differentiate between these 2 requests
  const mockUrl = accountTypeIdForMockUrl
    ? generatePath(url, { accountTypeId: accountTypeIdForMockUrl })
    : url

  return http.get(getCustomerMockUrl(mockUrl), ({ params }) => {
    const { accountTypeId } = params as {
      accountTypeId: AccountTypeId
    }

    if (spy) spy(accountTypeId)

    return HttpResponse.json(
      assignedValues || assignedAccountTypeValuesMockData,
    )
  })
}
