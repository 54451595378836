import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { formatAddress } from 'app/utils/formatAddress'
import { useProfileQuery } from 'app/containers/common/Profile'
import { useGetCountrySpecificVatIdTranslation } from 'app/hooks/useGetCountrySpecificVatIdTranslation'

export type AccountMenuCompanyInfoProps = {
  areCompanyDetailsHidden?: boolean
}

export const AccountMenuCompanyInfo = ({
  areCompanyDetailsHidden,
}: AccountMenuCompanyInfoProps) => {
  const { t } = useTranslation()
  const { data: companyData } = useProfileQuery()
  const companyRegistrationData = companyData?.companyRegistration
  const { getVatIdLabelValue } = useGetCountrySpecificVatIdTranslation()

  if (!companyRegistrationData) return null

  const { name: companyName, humanReadableId } = companyRegistrationData

  return (
    <Flex direction="column" gap="8px">
      <Flex direction="column" gap="4px">
        <Typography textStyle="textNormalSb">{companyName}</Typography>

        {humanReadableId && (
          <Typography>
            {t(translations.header.humanReadableId, {
              humanReadableId,
            })}
          </Typography>
        )}
      </Flex>

      {!areCompanyDetailsHidden && (
        <Typography color="contentB" mt="4px" data-testid="companyDetails">
          {formatAddress(companyRegistrationData)}
          <br />
          {getVatIdLabelValue(companyRegistrationData)}
        </Typography>
      )}
    </Flex>
  )
}
